import { Parallax } from "../../Animations/Parallax"
import { gsap } from "gsap"
export default class FullWidthImage {
  constructor({ scroll, container, screenSize, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.screenSize = screenSize
    this.DOM.image = this.DOM.el.querySelector(".js-full-width-img")
    this.init()
  }

  init() {
    // on desktop parallax image
    gsap.matchMedia().add("(min-width: 992px)", () => {
      Parallax(this.DOM.image)
    })
  }

  onResize() {
    this.init()
  }
}
