import Component from "../Classes/Component"
import gsap from "gsap";


export default class CookiePolicy extends Component {
    constructor () {
        super({
            element: '#gdpr-box',
            elements: {
                button: '.gdpr-button-accept'
            }
        })

        gsap.set(this.element, { autoAlpha:0, yPercent:100})
        this.init()
    } 

    init() {
        this.showCookiePolicy()
       // console.log('cookie time')
        // after preloader wait 1 secs then show cookie policy box
    }

    addEventListeners() {
        this.elements.button.addEventListener('click', this.close.bind(this))
    }

    close () {
        gsap.to(this.element, { autoAlpha:0, yPercent:100,duration:1.2, ease: 'expo.out'})
       
        // after animation remove from dom
        setTimeout( () => {
            this.element.parentNode.removeChild(this.element)
       }, 1000) 
    }

    show(element) {
        setTimeout( () => {
            gsap.to(element, { autoAlpha:1, yPercent: 0, duration:0.8, ease: 'expo.in'})
       }, 1000) //Show the div

       this.addEventListeners()
    }

    showCookiePolicy() {
        // show cookie policy again after a week
        let days = 7
      
        if(localStorage.last){
            if( (localStorage.last - Date.now() ) / (1000*60*60*24*days) >= 1){ //Date.now() is in milliseconds, so convert it all to days, and if it's more than 1 day, show the div
                
                this.show(this.element)
                localStorage.last = Date.now(); //Reset your timer
               
            }
        }
        else {
            localStorage.last = Date.now();
            this.show(this.element)
           
        }

    }

}