import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default class FAQ {
  constructor({ scroll, container, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.DOM.items = [...this.DOM.el.querySelectorAll(".js-accordion")]

    this.init()
  }

  showList(event) {
    const item = event.currentTarget
    const desc = item.querySelector(".js-desc")
    const icon = item.querySelector(".js-icon")

    icon.classList.toggle("js-active")
    item.classList.toggle("js-active")

    //get a perfect slide down
    if (desc.offsetHeight > 0) {
      // console.log('scrollUp')
      desc.style.height = `0px`
    } else {
      // console.log('scrollDown')
      desc.style.height = `${desc.scrollHeight}px`
    }

    setTimeout(() => {
      this.scroll.update()
    }, 500)
  }

  onResize() {}

  addEventListeners() {
    this.showAccordion = this.showList.bind(this)

    this.DOM.items.forEach((item) => {
      item.addEventListener("click", this.showAccordion)
    })
  }
  gutenberg() {
    if (window.acf) {
      this.init()
    }
  }
  init() {
    this.addEventListeners()
    this.onResize()
  }
}
