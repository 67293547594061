import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Lenis from "@studio-freight/lenis"

gsap.registerPlugin(ScrollTrigger)

export default class Scroll {
  constructor() {
    this.init()
  }

  init() {
    let mm = gsap.matchMedia()
    mm.add("(min-width: 769px)", () => {
      // Lenis smooth scrolling

      // if (!navigator.userAgent.includes('Safari')) {
      let lenis

      lenis = new Lenis({
        duration: 1.2,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        // easing: (h) => 1 - Math.pow(1 - h, 4),
        smoothWheel: true,
      })

      window.lenis = lenis

      lenis.on("scroll", ScrollTrigger.update)

      const scrollFn = (time) => {
        lenis.raf(time)
        requestAnimationFrame(scrollFn)
      }

      requestAnimationFrame(scrollFn)
    })
  }
}
