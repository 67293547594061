import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

import { SplitTitle } from "../Animations/SplitTitle"

export default class Journal {
  constructor({ body, scroll, container, header, preloader }) {
    this.scroll = scroll
    this.container = container
    this.header = header
    this.preloader = preloader

    this.DOM = { el: body }
    this.DOM.hero = this.DOM.el.querySelector(".js-hero")
    this.DOM.heroImage = this.DOM.el.querySelector(".js-hero-image")
    this.DOM.heroTitle = this.DOM.el.querySelector(".js-hero-title")
    this.DOM.heroLink = this.DOM.el.querySelector(".js-button")

    this.init()
    this.addEventListeners()
  }

  init() {
    if (this.DOM.heroTitle) this.heroTitle = SplitTitle(this.DOM.heroTitle)
    if (this.DOM.heroLink) gsap.set(this.DOM.heroLink, { autoAlpha: 0 })
    if (this.DOM.heroImage) gsap.set(this.DOM.heroImage, { scale: 1.25 })

    // RUN ANIMATION
    if (this.preloader) {
      this.preloader.on("completed", () => {
        this.heroAnimation()
      })
    } else {
      this.heroAnimation()
    }

    // due to plugin respnse time with loco scroll,
    setTimeout(() => {
      this.scroll.scroll.update()
    }, "2000")
  }

  heroAnimation() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: "expo",
        duration: 0.8,
      },
    })

    this.tlHero
      .to(this.DOM.heroImage, { scale: 1, duration: 2, ease: "power4.out" }, 0)
      .to(
        [this.heroTitle, this.DOM.heroLink],
        { duration: 1.2, yPercent: 0, stagger: 0.35, rotate: 0, autoAlpha: 1 },
        "-=90%"
      )
  }

  addEventListeners() {
    // this.scrollEvent = this.headerChange.bind(this)
    // this.scroll.scroll.on("scroll", this.scrollEvent)
  }
}
