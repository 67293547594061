import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default class HeaderFullWidthNav {
  constructor({ id, body, scroll, container }) {
    this.body = body
    this.scroll = scroll
    this.container = container
    this.DOM = { el: id }
    this.DOM.headerTop = this.DOM.el.querySelector(".js-header-top")
    this.DOM.toggle = this.DOM.el.querySelector(".js-toggle")
    this.DOM.logo = this.DOM.el.querySelector(".js-header-logo")
    this.DOM.mobileMenu = this.DOM.el.querySelector(".js-mobile-menu")
    this.DOM.line = this.DOM.el.querySelector(".js-header-line")
    this.DOM.topLevelButton = this.DOM.el.querySelector(".js-header-button")
    this.DOM.progressBar = document.querySelector(".js-progress-bar")
    this.DOM.pageCover = document.querySelector(".js-page-cover")

    this.DOM.darkmodeBtns = [
      ...this.DOM.el.querySelectorAll(".js-darkmode-btn"),
    ]
    this.DOM.journal = this.DOM.el.querySelector(".js-header-journal")
    this.DOM.items = [...this.DOM.el.querySelectorAll(".js-mask")]
    this.DOM.dropdowns = [...this.DOM.el.querySelectorAll(".js-dropdown")]
    this.DOM.subMenus = [...this.DOM.el.querySelectorAll(".js-submenu")]
    this.DOM.subLinks = [...this.DOM.el.querySelectorAll(".js-sub-link")]
    this.DOM.underlines = [...this.DOM.el.querySelectorAll(".js-underline")]

    this.DOM.MenuLineLeft = this.DOM.el.querySelector(".js-line-left")
    this.DOM.MenuLineBottom = this.DOM.el.querySelector(".js-line-bottom")
    this.DOM.MenuLineBottomRight = this.DOM.el.querySelector(
      ".js-line-bottom-right"
    )

    this.DOM.fullScreen = this.DOM.el.querySelector(".fullscreen-nav-js")
    this.DOM.headerPanel = this.DOM.el.querySelector(".js-panel--top")
    this.DOM.fullScreenPanelOne = this.DOM.el.querySelector(".js-panel--nav")
    this.DOM.fullScreenPanelTwo =
      this.DOM.el.querySelector(".js-panel--contact")
    this.DOM.fullScreenPanelThree =
      this.DOM.el.querySelector(".js-panel--project")
    this.DOM.fullScreenPanelFour = this.DOM.el.querySelector(".js-panel--image")
    this.DOM.projectImage = this.DOM.el.querySelector(".js-projected-img")

    this.headerState = "white"
    this.headerNameState = null
    this.darkModeState = "dark"

    this.init()
  }

  set() {
    if (this.DOM.topLevelButton)
      gsap.set(this.DOM.topLevelButton, { autoAlpha: 0 })
    if (this.DOM.logo) gsap.set(this.DOM.logo, { autoAlpha: 0 })
    if (this.DOM.toggle) gsap.set(this.DOM.toggle, { autoAlpha: 0 })
    if (this.DOM.journal)
      gsap.set(this.DOM.journal, { autoAlpha: 0, yPercent: 20 })
    if (this.DOM.projectImage) gsap.set(this.DOM.projectImage, { scale: 1.2 })
    if (this.DOM.line)
      gsap.set(this.DOM.line, { yPercent: 110, transformOrigin: "bottom" })

    // Set lines
    gsap.set(this.DOM.MenuLineBottom, { yPercent: 100 })
    gsap.set(this.DOM.MenuLineBottomRight, { yPercent: 100 })
    gsap.set(this.DOM.MenuLineLeft, { xPercent: -100 })

    // Set menu panels
    // down
    gsap.set(this.DOM.fullScreenPanelOne, { yPercent: 100 })
    //up
    gsap.set(this.DOM.headerPanel, { yPercent: -100 })
    //right
    gsap.set(this.DOM.fullScreenPanelTwo, { xPercent: -100 })
    // left
    gsap.set(this.DOM.fullScreenPanelThree, { xPercent: 100 })
    // up
    gsap.set(this.DOM.fullScreenPanelFour, { yPercent: -100 })

    //hide menu items
    if (this.DOM.items) gsap.set([this.DOM.items], { autoAlpha: 0 })
    if (this.DOM.underlines) gsap.set([this.DOM.underlines], { xPercent: -100 })
    if (this.DOM.subLinks)
      gsap.set(this.DOM.subLinks, { yPercent: 10, autoAlpha: 0 })
  }

  //Accessed through index.js
  scrolling(obj) {
    ScrollTrigger.update()
    let size = obj.limit.y - window.innerHeight - obj.limit.x

    //update progress bar with width using gsap utility
    // map range from pixels to 100
    let widthToProgress = gsap.utils.mapRange(0, size, 0, 100)
    let howMuchScrolled = widthToProgress(obj.scroll.y)

    this.header.DOM.progressBar.style.height = `${howMuchScrolled}%`
  }

  animate() {
    this.tl = gsap.timeline({
      defaults: {
        ease: "expo",
        duration: 0.4,
      },
    })

    if (this.DOM.line)
      this.tl.to(this.DOM.line, { yPercent: 0, ease: "back.inOut(1.7)" })

    this.tl
      .to(this.DOM.logo, { autoAlpha: 1 })
      .to(
        [this.DOM.topLevelButton, this.DOM.toggle],
        { autoAlpha: 1, stagger: 0.03 },
        "-=0.2"
      )

    if (this.DOM.line)
      this.tl.to(this.DOM.line, {
        yPercent: 110,
        duration: 0.3,
        ease: "back.inOut(1.7)",
        transformOrigin: "top",
      })
  }

  init() {
    this.addEventListeners()
    this.checkSectionforHeader()
  }

  // comes from headerManager.js
  onResize() {
    // make changes on resize
    if (
      (this.darkModeState === "light" && this.headerState === "transparent") ||
      (this.darkModeState === "light" &&
        this.headerState === "transparent-blur")
    ) {
      this.headerRevertState()
    }

    // SOMEWHERE THIS WORKS

    // else if (this.darkModeState === "dark" && this.headerState === "white") {
    //   this.headerChangeState("transparent")
    // } else {
    // }

    // calculate heights
    this.fullWidthNavHeight =
      window.innerHeight - this.DOM.headerTop.offsetHeight

    if (this.DOM.dropdowns) {
      this.DOM.dropdowns.forEach((dropdown) => {
        dropdown.classList.remove("js-open")
      })
    }

    if (this.DOM.subMenus) {
      this.DOM.subMenus.forEach((submenu) => {
        submenu.classList.remove("js-appear")
        submenu.style.height = "0px"
      })

      gsap.set(this.DOM.subLinks, { yPercent: 10, autoAlpha: 0 })
    }
  }

  toggleDropdown(e) {
    e.preventDefault()

    let currentMenu
    let targetSubMenu

    // SORT OUT TOGGLES
    const target = e.currentTarget
    // find selected toggle in array position
    const currentTarget = this.DOM.dropdowns.indexOf(target)

    // remove all over open targets
    this.DOM.dropdowns.forEach((dropdown, index) => {
      // find current target in loop
      if (index == currentTarget) {
        // if current target isn't open, open it then close everything else
        if (!dropdown.classList.contains("js-open"))
          return dropdown.classList.add("js-open")
      }
      dropdown.classList.remove("js-open")
    })

    //SORT OUT SUBMENUS
    targetSubMenu =
      target.parentElement.parentElement.querySelector(".js-submenu")
    // find selected toggle in array position
    currentMenu = this.DOM.subMenus.indexOf(targetSubMenu)

    this.DOM.subMenus.forEach((submenu, index) => {
      // find current target in loop
      if (index === currentMenu) {
        // if current target isn't open, open it then close everything else
        if (submenu.offsetHeight <= 0) {
          const inner = submenu.querySelector(".js-inner")
          const subLinks = [...inner.querySelectorAll(".js-sub-link")]

          gsap.to(subLinks, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.2,
            ease: "expo.out",
            stagger: 0.12,
          })

          submenu.style.height = `${inner.getBoundingClientRect().height}px`
          return
        }
      }

      // this is just getting selected array
      const itemsWithoutCurrent = this.DOM.subLinks.filter((x) => {
        return x !== currentMenu
      })
      gsap.set(itemsWithoutCurrent, { autoAlpha: 0, yPercent: 10 })
      submenu.style.height = "0px"
    })
  }

  scrollMenu(e) {
    this.DOM.fullScreenPanelOne.scrollTop += e.deltaY
  }

  openMenu() {
    window.lenis ? window.lenis.stop() : (this.body.style.overflow = "hidden")
    // prevent scrolling

    this.DOM.fullScreen.classList.add("js-open")
    this.DOM.toggle.classList.add("js-open")
    this.DOM.toggle.disabled = true

    // save header state before change
    this.beforeHeaderState = this.headerState

    // method for scrolling the menu if it is larger than the menu section
    this.scrollMenuEvent = this.DOM.fullScreenPanelOne.addEventListener(
      "mousewheel",
      (e) => this.scrollMenu(e)
    )

    this.openAnim = gsap.timeline({
      defaults: {
        ease: "sine.out",
        duration: 0.25,
      },
      onStart: () => {
        this.DOM.pageCover.classList.add("js-open")
        window.lenis
          ? window.lenis.stop()
          : (this.body.style.overflow = "hidden")
      },
      onComplete: () => {
        this.DOM.toggle.disabled = false
        gsap.set(this.DOM.projectImage, { clearProps: "all" })
      },
    })

    this.openAnim
      .to(this.DOM.fullScreen, { height: `${this.fullWidthNavHeight}px` })
      .to([this.DOM.MenuLineBottom], { yPercent: 0 })
      .to(
        [this.DOM.MenuLineLeft, , this.DOM.MenuLineBottomRight],
        { xPercent: 0, yPercent: 0, stagger: 0.15 },
        "-=25%"
      )
      .addLabel("panelTime", ">-0.25") // 0.25 seconds before the end of the previous animation
      .to(
        [this.DOM.projectImage],
        { ease: "expo.inOut", scale: 1, duration: 1 },
        "panelTime-=0.25"
      )
      .to(
        [
          this.DOM.headerPanel,
          this.DOM.fullScreenPanelOne,
          this.DOM.fullScreenPanelTwo,
          this.DOM.fullScreenPanelThree,
          this.DOM.fullScreenPanelFour,
        ],
        {
          ease: "Quint.easeOut",
          duration: 0.9,
          xPercent: 0,
          yPercent: 0,
          stagger: 0.05,
          onStart: () => {
            if (
              (this.darkModeState === "light" &&
                this.headerState === "transparent") ||
              (this.darkModeState === "light" &&
                this.headerState === "transparent-blur")
            ) {
              this.headerRevertState()
            }
            if (this.darkModeState === "dark" && this.headerState === "white") {
              this.headerChangeState("transparent")
            }
          },
        },
        "panelTime+=0.25"
      )

    if (this.DOM.journal)
      this.openAnim.to(
        [this.DOM.journal],
        { autoAlpha: 1, yPercent: 0 },
        "panelTime+=0.35"
      )

    this.openAnim
      .addLabel("EndOfAnim", ">-0.15") // 1seconds before the end of the previous animation
      .to([this.DOM.underlines], { xPercent: 0, stagger: 0.1 }, "EndOfAnim")
      .to([this.DOM.items], { autoAlpha: 1, stagger: 0.1 }, "EndOfAnim")
  }

  closeMenu(event) {
    this.closeAnim = gsap.timeline({
      defaults: {
        ease: "sine.out",
        duration: 0.25,
      },
      onStart: () => {
        window.lenis
          ? window.lenis.start()
          : (this.body.style.overflow = "auto")
        this.DOM.pageCover.classList.remove("js-open")
        this.DOM.toggle.disabled = true
      },
      onComplete: () => {
        this.DOM.toggle.classList.remove("js-open")
        this.DOM.fullScreen.classList.remove("js-open")
        this.body.style.overflow = "auto"

        this.DOM.fullScreenPanelOne.removeEventListener(
          "mousewheel",
          this.scrollMenuEvent
        )

        // Change state back if manually clicked to close menu NOT via barba page transition
        if (event) this.headerChangeState(this.beforeHeaderState)

        gsap.to(this.DOM.fullScreen, {
          height: "0px",
          onComplete: () => {
            this.DOM.toggle.disabled = false
          },
        })
      },
    })

    this.closeAnim
      .addLabel("startOfAnim", 0)
      .to(
        [this.DOM.underlines],
        { xPercent: -100, stagger: 0.1 },
        "startOfAnim"
      )
      .to([this.DOM.items], { autoAlpha: 0, stagger: 0.1 }, "startOfAnim")
      .addLabel("nextAnim", ">-0.5")
      .to(
        this.DOM.headerPanel,
        { yPercent: -100, ease: "Quart.easeInOut", duration: 0.6 },
        "nextAnim"
      )
      .to(
        this.DOM.fullScreenPanelOne,
        {
          yPercent: 100,
          ease: "Quart.easeInOut",
          duration: 0.6,
          onComplete: () => {
            !this.headerNameState
              ? this.headerChangeState(this.headerState)
              : this.headerChangeState(this.headerNameState)
          },
        },
        "nextAnim"
      )
      .to(
        this.DOM.fullScreenPanelTwo,
        { xPercent: -100, ease: "Quart.easeInOut", duration: 0.6 },
        "nextAnim"
      )
      .to(
        this.DOM.fullScreenPanelThree,
        { xPercent: 100, ease: "Quart.easeInOut", duration: 0.6 },
        "nextAnim"
      )
      .to(
        this.DOM.fullScreenPanelFour,
        { yPercent: -100, ease: "Quart.easeInOut", duration: 0.6 },
        "nextAnim"
      )
      .to(this.DOM.MenuLineBottom, { yPercent: 100 }, 0.25)
      .to(this.DOM.MenuLineLeft, { xPercent: -100 }, 0.25)
      .to(this.DOM.MenuLineBottomRight, { yPercent: 100 }, 0.25)
    if (this.DOM.journal)
      this.closeAnim.to(this.DOM.journal, { autoAlpha: 0, yPercent: 20 })

    this.closeAnim.to(this.DOM.projectImage, { scale: 1.2 })

    // turn off dropdowns
    this.onResize()
  }

  chooseMenu(e) {
    if (e.currentTarget.classList.contains("js-open")) {
      // close mennu
      this.closeMenu(e)
    } else {
      //open menu
      this.openMenu()
    }
  }

  removeCover() {
    this.closeMenu()

    setTimeout(() => {
      this.enterHeaderIn()
    }, 1000)
  }

  enterHeaderIn() {
    gsap.to(this.DOM.headerTop, {
      yPercent: 0,
      ease: "expo.out",
      duration: 1.2,
    })
  }

  leaveHeaderOut() {
    gsap.to(this.DOM.headerTop, {
      yPercent: -100,
      ease: "expo.out",
      duration: 1.2,
    })
  }

  headerChangeState(value) {
    this.DOM.headerTop.setAttribute("data-state", value)
    this.headerState = value
    this.headerNameState = value
  }

  headerRevertState() {
    this.DOM.headerTop.setAttribute("data-state", "white")
    this.headerState = "white"
  }

  checkSectionforHeader() {
    // use intersection observer to see if header is overlapping data-header sections
    // if so run headerWhite function
    // https://www.smashingmagazine.com/2021/07/dynamic-header-intersection-observer/
    // Only works on desktop

    this.headerStateSections = [
      ...document.querySelectorAll("[data-header-state]"),
    ]

    // if No sections
    if (!this.headerStateSections.length) return

    let halfHeight = this.DOM.headerTop.offsetHeight / 2

    const options = {
      // root is the scrollwrapper so can be used with scrollsmoother or just default
      rootMargin: `-${this.DOM.headerTop.offsetHeight / 2}px 0px -${
        window.innerHeight - this.DOM.headerTop.offsetHeight / 2
      }px 0px`,
      threshold: 0,
      // trackVisibility: true,
      // delay: 100
    }

    /* The callback that will fire on intersection */
    const onIntersect = (entries) => {
      // wipe each time
      this.headerResults = []
      this.headerNameState

      entries.forEach((entry) => {
        // return if section is not visible
        if (entry.isIntersecting) {
          this.headerNameState = entry.target.dataset.headerState
          this.headerResults.push(true)
        } else {
          this.headerResults.push(false)
        }
        return this.headerResults
      })

      // we have an array of all results of all divs are in view or not
      // if one of the results is true set the header, if all are false revert

      // checks whether an element is true
      const result = (element) => element === true

      if (this.headerResults.some(result)) {
        if (this.headerChangeState) this.headerChangeState(this.headerNameState)
      } else {
        if (this.headerRevertState) {
          this.headerRevertState()
          this.headerNameState = "white"
        }
      }
    }

    /* Create the observer */
    const observer = new IntersectionObserver(onIntersect, options)
    const that = this

    /* Set our observer to observe each section */
    this.headerStateSections.forEach((section) => {
      observer.observe(section)
    })
  }

  toggleDarkMode(e) {
    // Change between Light & Dark mode
    if (this.DOM.el.dataset.state === "dark") {
      // CHANGE TO LIGHT
      this.DOM.el.setAttribute("data-state", "light")
      this.headerRevertState()

      this.DOM.darkmodeBtns.forEach((btn) => {
        btn.classList.add("js-lightmode")
      })

      this.darkModeState = "light"
      this.DOM.pageCover.setAttribute("data-state", "light")
    } else {
      // CHANGE TO DARK
      this.DOM.el.setAttribute("data-state", "dark")

      this.headerChangeState("transparent")

      this.DOM.darkmodeBtns.forEach((btn) => {
        btn.classList.remove("js-lightmode")
      })

      this.darkModeState = "dark"
      this.DOM.pageCover.setAttribute("data-state", "dark")
    }
  }

  addEventListeners() {
    this.chooseEvent = this.chooseMenu.bind(this)
    this.dropdownEvent = this.toggleDropdown.bind(this)
    this.buttonEvent = this.toggleDarkMode.bind(this)

    this.DOM.toggle.addEventListener("click", this.chooseEvent)

    if (this.DOM.darkmodeBtns) {
      this.DOM.darkmodeBtns.forEach((btn) => {
        btn.addEventListener("click", this.buttonEvent)
      })
    }

    if (this.DOM.dropdowns) {
      this.DOM.dropdowns.forEach((dropdown) => {
        dropdown.addEventListener("click", this.dropdownEvent)
      })
    }

    window.addEventListener("resize", this.removeCover.bind(this))
  }
}
