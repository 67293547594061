
// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

export default class Testimonials  {
    constructor ({ scroll, container, id }) {
     this.DOM = {el: document.getElementById(id)}
     this.scroll = scroll
     this.container = container
     this.DOM.slider = this.DOM.el.querySelector('.testimonial__container');
     this.DOM.items = [...this.DOM.el.querySelectorAll('.testimonial__slide')];

    this.init()
    }

    swiperInit () {
        this.swiper = new Swiper(this.DOM.slider, {
          slidesPerView: 1,
          spaceBetween: 30,
          simulateTouch: true,
          loop: true,
          autoplay: {
            delay: 10000,
            disableOnInteraction: false
          },
          pagination: {
            el: ".ts__swiper-pagination",
            clickable: true
          }
      })
    }

  init () {
    this.swiperInit()
}


}

