import Hero from "../../Classes/Hero";
// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

// import Swiper styles
// import 'swiper/swiper-bundle.css';


export default class HeroSlideshow extends Hero {
    constructor () {
        super({
            id: 'hero-slideshow',
            heroClassname: '.hero-slideshow',
            heroItems: {
                slider: '.hero__slideshow__container',
                slides: '.hero__slideshow__slide',
                pagination: '.hs__swiper-pagination',
                buttonNext:'hs__button-next',
                buttonPrev:'hs__button-prev',
                scrollBar: 'hs__scrollbar'
            }
        })
    }
    create () {
        super.create()
        this.onResize()
    }
    init() {
        this.swiper() 
    }
    swiper() {
        this.swiperSlider = new Swiper( this.heroItems.slider, {
          spaceBetween: 30,
          effect: "fade",
          simulateTouch: true,
          loop: true,
          pagination: {
            el: '.hs__swiper-pagination',
            clickable: true
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          navigation: {
            nextEl: '.hs__button-next',
            prevEl: '.hs__button-prev',
          },
          on: {
            init: function () {
              console.log('swiper initialized');
            },
          },
          })
    }

    onResize() {
    }

    onLoad() {
        super.onLoad()
        this.swiper()
    }

    gutenberg () {
        super.gutenberg()
        super.create()
        if (window.acf) {
            this.swiper()
        //   window.acf.addAction('render_block_preview/type=hero-slideshow', this.swiper.bind(this))
        }
    }
}



