import { gsap } from "gsap"

export default class Gallery {
  constructor({ id, modalParent }) {
    this.modalParent = modalParent
    this.DOM = { el: id }
    this.DOM = { el: document.getElementById(id) }
    this.DOM.galleryInner = this.DOM.el.querySelector(".js-gallery")

    this.DOM.modal = this.DOM.el.querySelector(".js-modal")
    this.DOM.buttonGroup = this.DOM.el.querySelector(".js-button-group")
    this.DOM.closeButton = this.DOM.el.querySelector(".js-close")
    this.DOM.prevButton = this.DOM.el.querySelector(".js-prev")
    this.DOM.nextButton = this.DOM.el.querySelector(".js-next")
    this.DOM.modalImg = this.DOM.modal.querySelector(".js-modal-image")
    this.DOM.modalTitle = this.DOM.modal.querySelector(".js-modal-title")
    this.DOM.modalP = this.DOM.modal.querySelector(".js-modal-desc")
    this.DOM.currentNumber = this.DOM.modal.querySelector(".js-current-number")
    this.DOM.totalNumber = this.DOM.modal.querySelector(".js-total-number")

    this.DOM.images = [...this.DOM.el.querySelectorAll(".js-master-images")]
    this.DOM.pageImages = [...this.DOM.el.querySelectorAll(".js-image")]

    this.DOM.seeGallery = this.DOM.el.querySelector(".js-see-more")
    this.currentImage
    this.currentIndex = 0

    this.init()
  }

  showImage(element) {
    this.currentIndex = this.DOM.images.indexOf(element)

    if (!element) {
      console.info("no image to show")
      return
    }

    this.DOM.modalImg.src = element.dataset.image
    this.DOM.modalTitle.textContent = element.dataset.title
    this.DOM.modalP.textContent = element.dataset.description
    this.DOM.currentNumber.textContent = this.currentIndex + 1
    this.currentImage = element
    this.openModal()
  }

  reparentModal() {
    // save all childNodes + classes to use them again nexttime
    if (!this.modalElements) {
      this.modalClasses = []
      this.modalElements = [...this.DOM.modal.childNodes]
    }

    // append popup to new modal
    this.modalParent.append(...this.modalElements)

    // add classes to modal
    this.DOM.modal.classList.forEach((classes) => {
      this.modalParent.classList.add(classes)
      this.modalClasses.push(classes)
    })

    this.DOM.modalImg = this.modalParent.querySelector(".js-modal-image")
    this.DOM.modalTitle = this.modalParent.querySelector(".js-modal-title")
    this.DOM.modalP = this.modalParent.querySelector(".js-modal-desc")
  }

  openModal() {
    // First check if the modal is already open
    if (this.modalParent.classList.contains("js-open")) return

    this.reparentModal()

    this.openAnim = gsap.timeline({
      defaults: {
        ease: "sine.out",
        duration: 0.25,
      },
      onStart: () => {
        this.modalParent.classList.add("js-open")
      },
    })

    // animate in buttons
    this.openAnim.to(this.modalParent, { autoAlpha: 1 }).to(
      this.DOM.buttonGroup,
      {
        yPercent: 0,
        ease: "expo.in",
        duration: 0.15,
      },
      0
    )

    // Event listeners to be bound when we open the modal:
    window.addEventListener("keyup", this.handleKeyUp.bind(this))

    this.DOM.nextButton.addEventListener("click", this.clickNext)
    this.DOM.prevButton.addEventListener("click", this.clickPrev)
  }

  closeModal() {
    this.closeAnim = gsap.timeline({
      defaults: {
        ease: "sine.out",
        duration: 0.25,
      },
      onComplete: () => {
        this.modalParent.classList.remove("js-open")
        this.currentImage = ""
        // clean up reparent
        this.modalParent.innerHTML = ""
        // remove old classes
        this.modalClasses.forEach((classes) => {
          this.modalClasses = [...this.modalParent.classList]

          if (this.modalClasses.indexOf(classes)) {
            this.modalParent.classList.remove(classes)
          }
        })
      },
    })

    this.closeAnim.to(this.modalParent, { autoAlpha: 0 }).to(
      this.DOM.buttonGroup,
      {
        yPercent: 100,
        ease: "expo.out",
        duration: 0.6,
      },
      0
    )

    window.removeEventListener("keyup", this.handleKeyUp)

    this.DOM.nextButton.removeEventListener("click", this.clickNext)
    this.DOM.prevButton.removeEventListener("click", this.clickPrev)
  }

  handleClickOutside(e) {
    if (e.target === e.currentTarget) {
      this.closeModal()
    }
  }

  handleKeyUp(event) {
    if (event.key === "Escape") return this.closeModal()
    if (event.key === "ArrowRight") return this.showNextImage()
    if (event.key === "ArrowLeft") return this.showPrevImage()
  }

  showNextImage() {
    // update value
    this.currentIndex++
    // when to the last image change image to first in array
    if (this.currentIndex === this.DOM.images.length) this.currentIndex = 0

    this.showImage(this.DOM.images[this.currentIndex])
  }
  showPrevImage() {
    // update value
    this.currentIndex--

    // when back to the first image change image to last in array
    if (this.currentIndex < 0) this.currentIndex = this.DOM.images.length - 1

    this.showImage(this.DOM.images[this.currentIndex])
  }

  showGallery() {
    // show first image
    this.showImage(this.DOM.images[0])
  }

  chooseImage(e) {
    const image = e.currentTarget
    this.currentIndex = this.DOM.pageImages.indexOf(image)
    this.showImage(this.DOM.images[this.currentIndex])
  }

  addEventListeners() {
    this.handleClickEvent = this.handleClickOutside.bind(this)
    this.showGalleryClickEvent = this.showGallery.bind(this)
    this.closeEvent = this.closeModal.bind(this)
    this.chooseImageEvent = this.chooseImage.bind(this)

    this.DOM.pageImages.forEach((image) => {
      image.addEventListener("click", this.chooseImage.bind(this))
    })

    this.DOM.seeGallery.addEventListener("click", this.showGalleryClickEvent)
    this.DOM.closeButton.addEventListener("click", this.closeEvent)
    this.modalParent.addEventListener("click", this.handleClickEvent)
  }

  // REQUIRED for all Blocks | Activated by blockManager.js
  init() {
    gsap.set(this.DOM.buttonGroup, { yPercent: 100 })
    gsap.set(this.modalParent, { autoAlpha: 0 })
    this.clickNext = this.showNextImage.bind(this)
    this.clickPrev = this.showPrevImage.bind(this)

    this.addEventListeners()
    this.DOM.totalNumber.textContent = this.DOM.images.length
  }

  // OPTIONAL for all Blocks | Enables JS in Gutenberg Editor
  // gutenberg() {}
}
