import { SplitTitle } from "../../Animations/SplitTitle"
import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"
import Player from "@vimeo/player"

gsap.registerPlugin(ScrollTrigger)

export default class HeroFullWidth {
  constructor({ id, modalParent, scroll, preloader }) {
    this.modalParent = modalParent
    this.scroll = scroll
    this.DOM = { el: document.getElementById(id) }
    this.preloader = preloader
    this.matchMedia = gsap.matchMedia()

    if (!this.DOM.el) return
    this.DOM.image = this.DOM.el.querySelector(".js-hero-image")
    this.DOM.title = this.DOM.el.querySelector(".js-title")
    this.DOM.button = this.DOM.el.querySelector(".js-btn")
    this.DOM.journalComponent = this.DOM.el.querySelector(".js-journal")

    this.DOM.video = this.DOM.el.querySelector(".js-video")

    // Video
    this.DOM.videoComponent = this.DOM.el.querySelector(".js-video-showcase")
    if (this.DOM.videoComponent) {
      // this.videoloaded = false
      this.DOM.videoPopup = this.DOM.el.querySelector(".js-popup")
      this.DOM.videoTabs = [...this.DOM.el.querySelectorAll(".js-video-tab")]
      this.DOM.videoLocation = this.DOM.el.querySelector(".js-video-frame")

      this.videoOptions = {
        url: this.DOM.videoLocation.dataset.vimeo,
        loop: true,
      }
    }

    this.matchMedia = gsap.matchMedia()
  }

  setupFullWidthVideo() {
    // fullwidth video

    this.fullWidthVideoOptions = {
      url: this.DOM.video.dataset.src,
      loop: true,
    }

    this.matchMedia.add(
      // Desktop
      "(min-width: 992px)",
      () => {
        if (this.fullWidthplayer) return

        this.fullWidthplayer = new Player(
          this.DOM.video,
          this.fullWidthVideoOptions
        )

        this.fullWidthplayer.setVolume(0)
        this.fullWidthplayer.play()
      }
    )

    this.matchMedia.add(
      // Mobile
      "(max-width: 991px)",
      () => {
        if (!this.fullWidthplayer) return
        this.fullWidthplayer.destroy()
        this.fullWidthplayer = null
      }
    )
  }

  reparentModal() {
    // Pin Teamviewer / MINIMAP to page

    // save all childNodes + classes to use them again nexttime
    if (!this.videoElements) {
      this.videoClasses = []
      this.videoElements = [...this.DOM.videoPopup.childNodes]
    }

    // append popup to new modal
    this.modalParent.append(...this.videoElements)

    // add classes to modal
    this.DOM.videoPopup.classList.forEach((classes) => {
      this.modalParent.classList.add(classes)
      this.videoClasses.push(classes)
    })

    this.DOM.modalVideo = this.modalParent.querySelector(".js-video-frame")
    this.DOM.modalClose = this.modalParent.querySelector(".js-video-close")
  }

  init() {
    this.title = SplitTitle(this.DOM.title)
    // hide all elements

    if (this.DOM.video) {
      this.setupFullWidthVideo()
    } else {
      gsap.set(this.DOM.image, { scale: 1.2 })
    }

    gsap.set(this.DOM.button, { autoAlpha: 0 })

    if (this.DOM.journalComponent)
      gsap.set(this.DOM.journalComponent, { autoAlpha: 0 })

    if (this.DOM.videoComponent) {
      gsap.set(
        [this.DOM.videoComponent, this.DOM.videoPopup, this.modalParent],
        { autoAlpha: 0 }
      )
      gsap.set(this.DOM.videoTabs, { yPercent: 100 })
    }

    // make new in title orange
    this.title.forEach((line) => {
      if (line.outerText) {
        if (line.outerText.includes("new")) {
          const string = line.outerText
          const selected = "new"
          let regex = new RegExp(`${selected}`, "ig")
          let completedString = string.replace(
            regex,
            "<span class='orange'>new</span>"
          )

          return (line.innerHTML = completedString)
        }
      }
    })

    // split text
    this.onResize()
    this.onScroll()

    if (this.DOM.videoComponent) {
      this.videoSetup()
    }
  }

  onScroll() {
    if (!this.DOM.el) return

    this.tlScroll = gsap.timeline({
      scrollTrigger: {
        trigger: this.DOM.el,
        start: "center+=25% center",
        end: "bottom+=25% center",
        // markers: true,
        scrub: 0.8,
      },
      defaults: {
        ease: "none",
      },
    })

    this.matchMedia.add(
      // Desktop
      "(min-width: 992px)",
      () => {
        if (this.DOM.videoComponent)
          this.tlScroll.to(this.DOM.videoTabs, { yPercent: 100, stagger: 0.25 })
        // this.tlScroll
        //     .to([this.title, this.DOM.button], { autoAlpha:0, stagger: 0.25}, 0)
      }
    )
  }

  videoSetup() {
    this.player = new Player(this.DOM.videoLocation, this.videoOptions)

    this.preloader.on("completed", () => {
      this.tlVideo = gsap.timeline({
        defaults: {
          ease: "expo",
          duration: 0.8,
        },
        delay: 1,
      })

      this.tlVideo
        .to([this.DOM.videoComponent], { autoAlpha: 1 })
        .to([this.DOM.videoTabs], {
          yPercent: 0,
          ease: "expo.out",
          duration: 1.2,
          stagger: 0.25,
        })
    })

    // this.player.on("loaded", () => {

    // })
  }

  openVideo() {
    // set up reparent
    this.reparentModal()

    gsap.to(this.modalParent, {
      delay: 0.5,
      autoAlpha: 1,
      duration: 1.2,
      ease: "expo.out",
      onComplete: () => {
        this.player.play()
      },
    })
    // stop scrolling
    if (!this.clickCloseEvent) {
      this.clickCloseEvent = this.closeVideo.bind(this)
      this.DOM.modalClose.addEventListener("click", this.clickCloseEvent)
    }
  }

  closeVideo() {
    // close video
    gsap.to(this.modalParent, {
      autoAlpha: 0,
      duration: 1.2,
      ease: "expo.out",
      onStart: () => {
        this.player.pause()
      },
      onComplete: () => {
        // clean up reparent
        this.modalParent.innerHTML = ""
        // remove old classes
        this.videoClasses.forEach((classes) => {
          this.modalClasses = [...this.modalParent.classList]
          if (this.modalClasses.indexOf(classes)) {
            this.modalParent.classList.remove(classes)
          }
        })
      },
    })
  }

  animate() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: "expo",
        duration: 0.8,
      },
    })

    this.tlHero
      .to(this.DOM.image, { scale: 1, duration: 2, ease: "power4.out" }, 0)
      .to(
        [this.title, this.DOM.button],
        {
          duration: 1.2,
          yPercent: 0,
          stagger: "0.03",
          rotate: 0,
          autoAlpha: 1,
        },
        "-=90%"
      )

    if (this.DOM.journalComponent)
      this.tlHero.to(
        [this.DOM.journalComponent],
        {
          duration: 1.2,
          yPercent: 0,
          stagger: "0.03",
          rotate: 0,
          autoAlpha: 1,
        },
        "-=90%"
      )
  }

  onResize() {}

  // runs after preload
  onLoad() {
    this.animate()
    this.addEventListeners()
  }

  addEventListeners() {
    if (this.DOM.videoComponent) {
      this.clickOpenEvent = this.openVideo.bind(this)
      this.DOM.videoComponent.addEventListener("click", this.clickOpenEvent)
    }
  }
}
