// core version + navigation, pagination modules:
import { getMousePos } from '../../Utils'
import Swiper, { Scrollbar, Autoplay } from 'swiper'
// configure Swiper to use modules
Swiper.use([Scrollbar, Autoplay])

export default class Slideshow  {
    constructor ({ scroll, container, id }) {
     this.DOM = {el: document.getElementById(id)}
     this.scroll = scroll
     this.container = container
     this.DOM.slider = this.DOM.el.querySelector('.Slideshow-slider')
     this.DOM.cursor = this.DOM.el.querySelector('.slideshow-cursor')
     this.DOM.scrollbar = this.DOM.el.querySelector('.swiper-scrollbar')
     this.DOM.items = [...this.DOM.el.querySelectorAll('.ss--item')]
    //  this.in = this.cursorin.bind(this);
    //  this.out = this.cursorout.bind(this);
     this.init()
    }

    swiperInit () {
        this.swiper = new Swiper(this.DOM.slider, {
          slidesPerView: 2,
          spaceBetween: 5,
          grabCursor: true,
          updateOnImagesReady: true,
          loop: true,
          scrollbar: {
           el: ".ss-scrollbar",
           draggable: true
          },         
         autoplay: {
          delay: 2500,
          disableOnInteraction: false
         },
          breakpoints: {
               // when window width is >= 0px
            0: {
              slidesPerView: 1,
              spaceBetween: 5
            },
            // when window width is >= 640px
            768: {
              slidesPerView: 2,
              spaceBetween: 5
            },
            // when window width is >= 640px
            1220: {
              slidesPerView: 3,
              spaceBetween: 5
            }
          }

      })
    }

    // cursorin (event) {
    //   console.log(this)
    //   this.DOM.cursor.classList.add('active')
    //   this.mouse = getMousePos(event)
    //   this.DOM.cursor.style.transform = `translateX(${(this.mouse.x)}px) translateY(${this.mouse.y}px) scale(1.2)`;
    //   console.log('run', event)

    // }

    // cursorout () {
    //   console.log(this)
    //   this.DOM.cursor.classList.remove('active')
    // }

    // addEventListeners() {
    //   // Mouse effects on all links and others
    //   this.DOM.slider.addEventListener('mouseenter', this.in)
    //   this.DOM.slider.addEventListener('mouseout', this.out)
            
    // }

  init () {
   // this.addEventListeners()
    this.swiperInit()
  }
  gutenberg() {
    if (window.acf) {
        this.init()
    }
  }

}


