import Projects from "../Pages/Projects"
import Journal from "../Pages/Journal"

export default class PageManager {
  constructor({ body, scroll, container, header, preloader, blockManager }) {
    this.body = body
    this.scroll = scroll
    this.container = container
    this.header = header
    this.preloader = preloader
    this.BlockManager = blockManager
    this.init()
  }

  init() {
    // Important add data-page to #content div for js to be found
    // please see index.js
    this.body = document.querySelector("body")

    // Check what page is in the DOM
    if (this.body.classList.contains("single-ourprojects")) {
      this.thePage = new Projects({
        body: this.body,
        scroll: this.scroll,
        container: this.scrollWrapper,
        header: this.header,
        preloader: this.preloader,
      })
    } else if (this.body.classList.contains("page-journal")) {
      this.thePage = new Journal({
        body: this.body,
        scroll: this.scroll,
        container: this.scrollWrapper,
        header: this.header,
        preloader: this.preloader,
      })
    } else {
      // nothing
    }
  }

  deletePage() {
    if (this.thePage) delete this.thePage
  }

  // barba enter
  enterPageTransition(pageitem) {
    // Check what page is in the DOM

    if (pageitem === "our-projects") {
      this.thePage = new Projects({
        body: this.body,
        scroll: this.scroll,
        container: this.scrollWrapper,
        header: this.header,
        preloader: this.preloader,
      })
    } else if (pageitem === "journal") {
      this.thePage = new Journal({
        body: this.body,
        scroll: this.scroll,
        container: this.scrollWrapper,
        header: this.header,
        preloader: this.preloader,
      })
    } else {
      // nothing
    }
  }

  onResize(screenSize) {
    this.screenSize = screenSize

    this.currentBlocks.forEach((block) => {
      // console.log(block)
      if (block.onResize) {
        block.onResize(this.screenSize)
      }
      // this.blocks[blocks].onResize(this.screenSize)
    })
  }
}
