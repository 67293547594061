import barba from "@barba/core"
import barbaPrefetch from "@barba/prefetch"
import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

barba.use(barbaPrefetch)

// - choose which js classes to load based on new prefetch content,
// - use hooks to scroll to top of page after new transition,
// - find away to load ninja forms, failing this use contact form 7 instead

export default class PageTransition {
  constructor({
    scroll,
    body,
    blockManager,
    pageManager,
    heroManager,
    cursor,
    header,
    pages,
  }) {
    this.scroll = scroll
    this.body = body
    this.BlockManager = blockManager
    this.PageManager = pageManager
    this.HeroManager = heroManager
    this.cursor = cursor
    this.header = header

    this.hooks()
  }

  hooks() {
    // google analytics
    barba.hooks.after(() => {
      ga("set", "page", window.location.pathname)
      ga("send", "pageview")

      // locomotive scroll
      window.lenis ? window.lenis.scrollTo(0, 0) : window.scrollTo(0, 0)

      // this.scroll.scrollTo(0)
      // this.scroll.update()
    })

    this.init()
  }

  init() {
    let that = this
    barba.init({
      debug: true,
      // prevents links with 'journal' to used in barba so plugin can run
      prevent: ({ el }) => {
        if (el.href.includes("journal") || el.href.includes("insights")) {
          // console.log(el.href)
          return true
        }
        // /journal/.test(el.href)
        // /insights/.test(el.href)
      },
      transitions: [
        {
          name: "opacity-transition",
          //  sync: true,
          leave(data) {
            // get all scrollTriggers and clear them
            console.log(ScrollTrigger)
            ScrollTrigger.getAll().forEach((trigger) => {
              trigger.kill()
            })

            // on leave destroy modules currently running
            that.header.closeMenu()
            that.header.leaveHeaderOut()
            that.BlockManager.leavePageTransition(data.current.container)

            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 1.2,
            })
          },
          beforeEnter(data) {
            that.BlockManager.deleteBlocks()
            that.HeroManager.deleteHero()
            that.PageManager.deletePage()
          },
          enter(data) {
            gsap.from(data.next.container, {
              opacity: 0,
              duration: 1.2,
              onStart: () => {
                that.header.enterHeaderIn()
                that.header.checkSectionforHeader()

                // const facetElements = document.querySelectorAll([
                //   ".facetwp-template",
                //   ".facetwp-facet",
                // ])

                // console.log(typeof FWP !== "undefined")

                // if (facetElements.length > 0) {
                //   if (typeof FWP !== "undefined") {
                //     console.log("facetwp loaded")
                //     if (!FWP.loaded) {
                //       console.log("facetwp not loaded")
                //       FWP.init()
                //       FWP.fetchData()
                //     }
                //   }

                //   // Prevent FacetWP updating history state, which interferes with Swup -> https://facetwp.com/help-center/developers/the-facetwp-url/#disable-facetwps-query-string
                //   document.addEventListener("facetwp-refresh", function () {
                //     if (!FWP.loaded) {
                //       FWP.setHash = function () {
                //         /* empty */
                //       }
                //     }
                //   })
                // }

                // work
                if (
                  data.next.container.dataset.title === "our-projects" ||
                  data.next.container.dataset.title === "journal"
                ) {
                  that.PageManager.enterPageTransition(
                    data.next.container.dataset.title
                  )
                } else {
                  that.HeroManager.enterPageTransition()
                }
              },
              onComplete: () => {
                that.BlockManager.enterPageTransition()

                if (data.next.container.dataset.title === "Typeform") {
                  that.header.headerChangeState("transparent")
                  // console.log("onComplete:", that.header.headerNameState)
                }

                // reinit cursor enter and leave
                if (that.cursor) {
                  // Mouse effects on all links and others
                  ;[
                    ...document.querySelectorAll("a, button, .accordian-item"),
                  ].forEach((link) => {
                    link.addEventListener("mouseenter", () =>
                      that.cursor.enter()
                    )
                    link.addEventListener("mouseleave", () =>
                      that.cursor.leave()
                    )
                  })
                }
              },
            })
          },
        },
      ],
    })
  }
}
