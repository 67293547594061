import { SplitTitle } from "../Animations/SplitTitle"
import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default class Projects {
  constructor({ body, scroll, container, header, preloader }) {
    this.scroll = scroll
    this.container = container
    this.header = header
    this.preloader = preloader

    this.DOM = { el: body }
    this.DOM.heroImage = this.DOM.el.querySelector(".js-hero")
    this.DOM.heroTitle = this.DOM.el.querySelector(".js-project-title")
    this.DOM.heroLabel = this.DOM.el.querySelector(".js-label")
    this.DOM.images = [...this.DOM.el.querySelectorAll(".js-image")]
    this.DOM.categories = [...this.DOM.el.querySelectorAll(".js-category")]

    this.init()
    this.addEventListeners()
  }

  init() {
    this.heroTitle = SplitTitle(this.DOM.heroTitle)
    this.heroLabel = SplitTitle(this.DOM.heroLabel)
    gsap.set(this.DOM.categories, { autoAlpha: 0, yPercent: 50 })
    gsap.set(this.DOM.heroImage, { scale: 1.25 })
    // console.log(this.preloader.isDestroyed)

    // RUN ANIMATION
    if (!this.preloader.isDestroyed) {
      this.preloader.on("completed", () => {
        this.heroAnimation()
      })
    } else {
      this.heroAnimation()
    }

    this.imageScrollAnimation()
  }

  heroAnimation() {
    this.tlHero = gsap.timeline({
      defaults: {
        ease: "expo",
        duration: 0.8,
      },
    })

    this.tlHero
      .to(this.DOM.heroImage, { scale: 1, duration: 2, ease: "power4.out" }, 0)
      .to(
        [this.heroTitle, this.heroLabel],
        { duration: 1.2, yPercent: 0, stagger: "0.35", rotate: 0 },
        "-=90%"
      )
      .to(
        this.DOM.categories,
        { autoAlpha: 1, yPercent: 0, stagger: 0.2 },
        "-=50%"
      )
  }

  addEventListeners() {}

  imageScrollAnimation() {
    // only plays on desktop
    gsap.matchMedia().add("(min-width: 992px)", () => {
      gsap.set(this.DOM.images, { yPercent: 10, autoAlpha: 0 })

      this.DOM.images.forEach((image) => {
        gsap.to(image, {
          scrollTrigger: {
            trigger: image,
            start: "top bottom-=10%",
            toggleActions: "play pause resume reset",
          },
          yPercent: 0,
          autoAlpha: 1,
        })
      })

      return () => {
        // optional
        // custom cleanup code here (runs when it STOPS matching)
      }
    })
  }
}
