import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
import { isInViewport } from "../../Utils"

export default class Timeline {
  constructor({ scroll, container, screenSize, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.screenSize = screenSize
    this.DOM.container = this.DOM.el.querySelector(".case-group")
    this.DOM.items = [...this.DOM.el.querySelectorAll(".scroll-text-box")]
    this.DOM.images = [...this.DOM.el.querySelectorAll(".case_item__image")]
    this.DOM.scrollTabs = [
      ...this.DOM.el.querySelectorAll(".scroll--menu_item"),
    ]
    this.DOM.caseTitles = [
      ...this.DOM.el.querySelectorAll(".case__title__title"),
    ]
    this.DOM.caseDesc = [...this.DOM.el.querySelectorAll(".case__desc__desc")]
    this.DOM.sections = [...this.DOM.el.querySelectorAll(".case__section")]

    this.init()
  }

  titlesIn() {
    this.tl = gsap.timeline({ paused: true })

    this.DOM.items.forEach((section) => {
      gsap.set([this.DOM.caseTitles], { yPercent: 100 })
      gsap.set([this.DOM.caseDesc], { autoAlpha: 0 })

      this.tl.to(
        [
          section.querySelector(".case__title__title"),
          section.querySelector(".case__desc__desc"),
        ],
        {
          scrollTrigger: {
            trigger: section,
            start: "top center",
            end: "bottom center",
            once: true,
            invalidateOnRefresh: true,
            // markers: true
          },
          yPercent: 0,
          duration: 1.2,
          autoAlpha: 1,
          ease: "Power4.easeOut.easeOut",
          stagger: 0.25,
        }
      )
    })
  }

  fade() {
    this.fade = gsap.timeline({ paused: true })
    //   this.first = gsap.to(this.DOM.images, { yoyo: true, rotation:"360", repeat:-1, ease: 'none'})

    gsap.set([this.DOM.images], { autoAlpha: 0 })

    this.DOM.images.forEach((section) => {
      this.fade.to(section, {
        scrollTrigger: {
          trigger: section,
          start: "top center",
          end: "center center",
          markers: true,
          scrub: true,
          invalidateOnRefresh: true,
        },
        autoAlpha: 1,
      })
      //  this.imageAnim.push(imageAnim)
    })
  }

  animate() {
    this.DOM.items.forEach((section) => {
      if (isInViewport(section)) {
        for (var i = 0; i < this.DOM.images.length; i++) {
          let dataImage = this.DOM.images[i].getAttribute("data-image")
          let dataSection = section.getAttribute("data-section")
          //  this.DOM.images[i].classList.remove("js-item_visible");
          this.DOM.scrollTabs[i].classList.remove("js-tab-visible")

          if (dataImage == dataSection) {
            // this.DOM.images[i].classList.add("js-item_visible");
            this.DOM.scrollTabs[i].classList.add("js-tab-visible")
          }
        }
      }
    })
  }

  addEventlisteners() {
    document.addEventListener("scroll", this.animate.bind(this))
  }

  // run on resize
  onResize(screensized) {
    // Check if we are running on page load or on screen resize
    let screen
    screensized ? (screen = screensized) : (screen = this.screenSize)

    //   console.log(this.fade)

    if (screen === "desktop") {
      this.tl.play(0)
      this.fade.play(0)

      ScrollTrigger.refresh()
    } else if (screen === "mobile" || screen === "tablet") {
      gsap.set([this.DOM.caseTitles, this.DOM.caseDesc, this.DOM.images], {
        clearProps: "all",
      })
      this.fade.pause(0)
      this.tl.pause(0)

      console.log("pause")
      ScrollTrigger.refresh()
    } else {
    }
  }

  init() {
    this.fade()
    this.titlesIn()
    this.addEventlisteners()
  }
}
