import { gsap } from "gsap"

export default class ProjectHoverColumns {
  constructor({ id, screenSize }) {
    this.screenSize = screenSize
    this.DOM = { el: document.getElementById(id) }
    this.DOM.cards = [...this.DOM.el.querySelectorAll(".types__card")]
    this.DOM.backgroundImages = [...this.DOM.el.querySelectorAll(".types__img")]
    this.DOM.cardImages = [...this.DOM.el.querySelectorAll(".card__bg")]
    this.DOM.cardContent = [...this.DOM.el.querySelectorAll(".card__content")]
    this.DOM.buttons = [...this.DOM.el.querySelectorAll(".main-link-white")]
    this.mm = gsap.matchMedia()
    this.in = this.mousein.bind(this)
    this.out = this.mouseout.bind(this)
    this.init()
  }

  mousein(event) {
    const target = event.target
    // hide all cards
    this.DOM.cardImages.forEach((image) => {
      image.style.opacity = 0
    })
    // hide all title
    this.DOM.cardContent.forEach((content) => {
      content.style.opacity = 0
    })
    // show current card
    target.querySelector(".card__bg").style.opacity = 1
    // show matching background image
    for (let i = 0; i < this.DOM.backgroundImages.length; i++) {
      if (this.DOM.backgroundImages[i].dataset.title == target.dataset.title) {
        this.DOM.backgroundImages[i].style.opacity = 0.5
        this.DOM.cardContent[i].style.opacity = 1
        this.DOM.buttons[i].classList.add("on")
      }
    }
  }

  mouseout() {
    this.DOM.cardImages.forEach((image) => {
      image.style.opacity = 0.7
    })
    this.DOM.backgroundImages.forEach((image) => {
      image.style.opacity = 0
    })
    this.DOM.cardContent.forEach((content) => {
      content.style.opacity = 1
    })
    this.DOM.buttons.forEach((button) => {
      button.classList.remove("on")
    })
  }

  init() {
    this.addEventListeners()
  }

  onResize(screensized) {
    // Check if we are running on page load or on screen resize
    let screen
    screensized ? (screen = screensized) : (screen = this.screenSize)

    if (screen === "desktop") {
      this.addEventListeners()
    } else if (screen === "mobile" || screen === "tablet") {
      this.removeEventListeners()
    } else {
    }

    // size backgrounds to full height of block

    this.mm.add("(min-width: 992px)", () => {
      this.DOM.cardImages.map(
        (image) => (image.style.height = this.DOM.el.offsetHeight + "px")
      )
    })
  }
  gutenberg() {
    // this.in = this.mousein.bind(this);
    // this.out = this.mouseout.bind(this);

    if (window.acf) {
      //  this.init()
    }
  }
  addEventListeners() {
    this.DOM.cards.forEach((card) => {
      card.addEventListener("mouseenter", this.in)
      card.addEventListener("mouseout", this.out)
    })
  }
  removeEventListeners() {
    this.DOM.cards.forEach((card) => {
      card.removeEventListener("mouseenter", this.in)
      card.removeEventListener("mouseout", this.out)
    })
  }
}
