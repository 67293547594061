import Timeline from "../Blocks/Page/Timeline"
// import Services from '../Blocks/page/Services'
import Testimonials from "../Blocks/page/Testimonials"
import Gallery from "../Blocks/page/Gallery"
import Accordion from "../Blocks/page/Accordion"
import List from "../Blocks/page/List"
import FAQ from "../Blocks/page/Faq"
import Slideshow from "../Blocks/page/Slideshow"
import LatestProjects from "../Blocks/page/LatestProjects"
import ImageSection from "../Blocks/page/ImageSection"
import ProjectHoverColumns from "../Blocks/page/ProjectHoverColumns"
import Process from "../Blocks/page/Process"
import FullWidthImage from "../Blocks/page/FullWidthImage"
import Typeform from "../Blocks/page/Typeform"
import JournalContactForm from "../Blocks/page/JournalContactForm"

import PostDisplay from "../Blocks/Post/PostDisplay"
import Portfolio from "../Blocks/Post/Portfolio"

// Newspace Blocks
import FAQPage from "../Blocks/newspace/FAQPage"

// import ImageSection from 'Blocks/Page/ImageSection'

export default class BlockManager {
  constructor({ blocks, scroll, wrapper, screenSize, header, modalParent }) {
    this.blocks = blocks
    this.scroll = scroll
    this.screenSize = screenSize
    this.scrollWrapper = wrapper
    this.modalParent = modalParent
    this.header = header
    this.currentBlocks = []
    this.onFirstPageLoad()

    if (typeof wp != "undefined" && wp.blockEditor) {
      this.gutenberg()
    }
  }

  deleteBlocks() {
    if (this.currentBlocks) {
      delete this.currentBlocks
    }
  }

  enterPageTransition(next) {
    // init
    const blocksOnNewPage = [...document.querySelectorAll("[data-block]")]

    blocksOnNewPage.forEach((block) => {
      this.blockCheck(block)
    })

    //  console.log(this.currentBlocks)
  }

  leavePageTransition(current) {
    //destroy
    if (this.currentBlocks)
      this.currentBlocks.forEach((block) => {
        if (block.destroy) block.destroy()
      })
  }

  onFirstPageLoad() {
    //  console.log(this.blocks)
    this.blocks.forEach((block) => {
      this.blockCheck(block)
    })
  }

  blockCheck(block) {
    if (!this.currentBlocks) {
      this.currentBlocks = []
    }

    const className = block.className
    const id = block.id
    // split classnames into an array
    let keys = className.split(" ")
    // filter out any classes that match 'alignfull' or contain 'wp-block'
    keys = keys.filter((cl) => cl !== "alignfull" || !cl.includes("wp-block"))
    // flatten array
    keys = keys[0]

    switch (keys) {
      case "PostDisplay":
        const pd = new PostDisplay(id)
        this.currentBlocks.push(pd)
        break
      case "Portfolio":
        const pz = new Portfolio({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id,
          header: this.header,
        })
        this.currentBlocks.push(pz)
        break
      case "Testimonials":
        const t = new Testimonials({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id,
        })
        this.currentBlocks.push(t)
        break
      case "Typeform":
        const type = new Typeform(id)
        //   console.log(type)
        this.currentBlocks.push(type)
        break
      case "JournalContactForm":
        const jcf = new JournalContactForm(id)
        this.currentBlocks.push(jcf)
        break
      case "FAQPage":
        const faqpage = new FAQPage({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id,
          header: this.header,
        })
        this.currentBlocks.push(faqpage)
        break
      case "Slideshow":
        const s = new Slideshow({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id,
        })
        this.currentBlocks.push(s)
        break
      case "Accordion":
        const a = new Accordion({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id,
        })
        this.currentBlocks.push(a)
        break
      case "List":
        const l = new List(id)
        this.currentBlocks.push(l)
        break
      case "TimelineProcess":
        const p = new Process({
          scroll: this.scroll,
          screenSize: this.screenSize,
          container: this.scrollWrapper,
          header: this.header,
          id: id,
        })
        this.currentBlocks.push(p)
        break
      case "ProjectHoverColumns":
        const phc = new ProjectHoverColumns({
          id: id,
          screenSize: this.screenSize,
        })
        this.currentBlocks.push(phc)
        break
      case "FullWidthImage":
        const fwi = new FullWidthImage({
          scroll: this.scroll,
          screenSize: this.screenSize,
          container: this.scrollWrapper,
          id: id,
        })
        this.currentBlocks.push(fwi)
        break
      case "ImageSection":
        const is = new ImageSection({ screenSize: this.screenSize, id: id })
        this.currentBlocks.push(is)
        break
      case "Gallery":
        const g = new Gallery({
          id: id,
          modalParent: this.modalParent,
        })
        this.currentBlocks.push(g)
        break
      case "FAQ":
        const faq = new FAQ({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id,
        })
        this.currentBlocks.push(faq)
        break
      case "Timeline":
        const tl = new Timeline({
          scroll: this.scroll,
          container: this.scrollWrapper,
          screenSize: this.screenSize,
          id: id,
        })
        this.currentBlocks.push(tl)
        break
      case "LatestProjects":
        const lpj = new LatestProjects({
          scroll: this.scroll,
          container: this.scrollWrapper,
          id: id,
        })
        this.currentBlocks.push(lpj)
        break
    }
  }

  // does block need to enable gutenberg  back end editing
  gutenberg() {
    this.currentBlocks.forEach((block) => {
      if (block.gutenberg) block.gutenberg()
    })
  }

  onResize(screenSize) {
    this.screenSize = screenSize

    if (this.currentBlocks) {
      this.currentBlocks.forEach((block) => {
        // console.log(block)
        if (block.onResize) {
          block.onResize(this.screenSize)
        }
        // this.blocks[blocks].onResize(this.screenSize)
      })
    }
    // console.log(this)
    //  console.log('blocks resize')
  }
}
