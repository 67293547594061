// core version + navigation, pagination modules:
import Swiper, { Scrollbar, Autoplay } from "swiper"
// configure Swiper to use modules
Swiper.use([Scrollbar, Autoplay])

export default class LatestProjects {
  constructor({ scroll, container, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.DOM.slider = this.DOM.el.querySelector(".latest-projects-slider")
    this.DOM.scrollbar = this.DOM.el.querySelector(".lp-scrollbar")
    this.DOM.items = [...this.DOM.el.querySelectorAll(".lp-slider-item")]

    if (this.DOM.slider) {
      this.init()
    }
  }

  swiperInit() {
    this.swiper = new Swiper(this.DOM.slider, {
      slidesPerView: 3,
      spaceBetween: 10,
      grabCursor: true,
      updateOnImagesReady: true,
      loop: true,
      scrollbar: {
        el: ".lp-scrollbar",
        draggable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      breakpoints: {
        1920: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        1220: {
          slidesPerView: 3,
          spaceBetween: 7.5,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        0: {
          slidesPerView: 1,
          spaceBetween: 5,
        },
      },
    })
  }

  init() {
    this.swiperInit()
  }
  gutenberg() {
    if (window.acf) {
      if (this.DOM.slider) {
        this.init()
      }
    }
  }
}
