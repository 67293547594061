import { gsap } from "gsap"
import { SplitText } from "gsap/SplitText"
gsap.registerPlugin(SplitText)

// This Variable splits a title into lines and fades it up

export const SplitTitle = (title) => {
  const newTitle = new SplitText(title, {
    type: "lines",
    linesClass: "lineChild",
  })
  const innerTitle = new SplitText(title, {
    type: "lines",
    linesClass: "lineParent",
  })
  gsap.set(newTitle.lines, { yPercent: 130, rotate: 5 })

  return newTitle.lines
}
