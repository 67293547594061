import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default class FAQPage {
  constructor({ scroll, container, id, header }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.header = header

    this.DOM.pinSection = this.DOM.el.querySelector(".js-pinned")
    this.DOM.filters = [...this.DOM.el.querySelectorAll(".js-filter")]
    this.DOM.accordions = [...this.DOM.el.querySelectorAll(".js-accordion")]
    this.DOM.sections = [...this.DOM.el.querySelectorAll(".js-section")]
    this.DOM.contentArea = this.DOM.el.querySelector(".js-content-area")

    this.matchMedia = gsap.matchMedia()

    this.init()
  }

  showList(event) {
    const item = event.currentTarget

    const desc = item.querySelector(".js-desc")
    const icon = item.querySelector(".js-icon")

    icon.classList.toggle("js-active")
    item.classList.toggle("js-active")

    //get a perfect slide down
    if (desc.offsetHeight > 0) {
      // console.log('scrollUp')
      desc.style.height = `0px`
    } else {
      // console.log('scrollDown')
      desc.style.height = `${desc.scrollHeight}px`
    }
  }

  onResize() {}

  scrollTofiltered(e) {
    const linkToSearch = e.currentTarget.getAttribute("data-filter")

    this.DOM.sections.forEach((section) => {
      // on click scroll to correct Section
      if (linkToSearch === section.getAttribute("data-filter")) {
        window.lenis
          ? window.lenis.scrollTo(section, {
              offset: -section.offsetHeight,
            })
          : window.scrollTo(section, 0)
      }
    })
  }

  addEventListeners() {
    this.showAccordion = this.showList.bind(this)
    this.showfilter = this.scrollTofiltered.bind(this)

    this.DOM.accordions.forEach((item) => {
      item.addEventListener("click", this.showAccordion)
    })

    this.DOM.filters.forEach((filter) => {
      filter.addEventListener("click", this.showfilter)
    })
  }
  gutenberg() {
    if (window.acf) {
      this.init()
    }
  }

  pinOnScroll() {
    // Pin filters to side of page
    this.matchMedia.add(
      // Desktop
      "(min-width: 992px)",
      () => {
        ScrollTrigger.create({
          trigger: this.DOM.pinSection,
          endTrigger: this.DOM.contentArea,
          start: `top center`,
          end: `bottom bottom-=${
            this.DOM.sections[this.DOM.sections.length - 1].offsetHeight
          }`,
          pin: true,
          pinSpacing: false,
          pinType: "transform",
          anticipatePin: 1,
          //  markers: true,
        })
      }
    )
  }

  updateSections() {
    this.DOM.sections.forEach((section, index) => {
      const title = section.querySelector(".js-pinned-title")

      this.matchMedia.add(
        // Desktop
        "(min-width: 992px)",
        () => {
          // Pins title of section on scroll
          ScrollTrigger.create({
            trigger: title,
            endTrigger: section,
            start: `top top+=${this.header.DOM.el.offsetHeight - 2}`,
            end: `bottom bottom`,
            pin: true,
            pinSpacing: false,
          })

          // Updates active filter (in pinned filter group) when scrolling
          ScrollTrigger.create({
            trigger: section,
            start: "top center",
            end: "bottom center",
            //  markers: true,
            onEnter: () => {
              this.DOM.filters[index].classList.add("js-active")
            },
            onLeave: () => {
              this.DOM.filters[index].classList.remove("js-active")
            },
            onEnterBack: () => {
              this.DOM.filters[index].classList.add("js-active")
            },
            onLeaveBack: () => {
              this.DOM.filters[index].classList.remove("js-active")
            },
          })
        }
      )
    })
  }

  init() {
    this.addEventListeners()
    this.onResize()
    this.pinOnScroll()
    this.updateSections()
  }
}
