import Header from "./Components/Header"
import HeaderFullWidthNav from "./Components/HeaderFullWidthNav"
import Preloader from "./Components/Preloader"
import Modal from "./Components/Modal"
import Cursor from "./Components/cursor"
import CookiePolicy from "./Components/CookiePolicy"
import Scroll from "./Components/Scroll"

// Classes
import BlockManager from "./Classes/BlockManager"
import HeroManager from "./Classes/HeroManager"
import PageManager from "./Classes/PageManager"
import Detection from "./Classes/Detection"

// Page Transitions
import PageTransition from "./PageTransitions/barba"

class App {
  constructor() {
    // Checking if we are viewing on backend or not
    // turn on for gutenberg
    if (typeof wp != "undefined") {
      // BACKEND
      if (wp.blockEditor) {
        // GUTENBERG
        console.log("Backend: Gutenberg")
        window._wpLoadBlockEditor.then(this.addEventListeners())
        return
      } else {
        // console.log(wp, Object.keys(wp), window.acf)
        // Frontend but landed here by accident
        // Backend but not Gutenberg
        window.acf ? console.log("Backend: Not Gutenberg") : this.init()
      }
    } else {
      // FRONTEND
      this.init()
    }
  }

  init() {
    this.consoleMessage()
    this.detection()
    this.createContent()
    this.createSettings()
    this.createPages()
    this.createHero()
    this.createBlocks()
    this.addEventListeners()
    this.barba()
    this.onResize()
  }

  barba() {
    this.PageTransition = new PageTransition({
      scroll: this.smoothScroll,
      body: this.body,
      blockManager: this.BlockManager,
      heroManager: this.HeroManager,
      pageManager: this.PageManager,
      cursor: this.cursor,
      header: this.header,
    })
  }

  createPages() {
    // Check this if you want to create a new JS Block
    this.PageManager = new PageManager({
      body: this.body,
      scroll: this.smoothScroll,
      container: this.container,
      header: this.header,
      preloader: this.preloader,
      blockManager: this.BlockManager,
    })
  }

  consoleMessage() {
    console.groupCollapsed(
      "%cThis website was developed by Dirty Martini Marketing",
      "color:white, background:black"
    )
    console.log(
      "For all your website needs, please contact us | https://dirty-martini.com"
    )
    console.log(
      "Website theme built by Charles Farrelly & Maxwell Kirwin, https://maxwellkirwin.co.uk"
    )
    console.groupEnd()
  }

  createSettings() {
    this.preloaderInView = document.querySelector(".js-preloader")
    this.modalInView = document.querySelector("#myModal")
    this.cursorOnPage = document.querySelector(".cursor")
    this.devTools = document.querySelector(".dev-tools")
    this.gdpr = document.querySelector("#gdpr-box")

    if (this.preloaderInView) {
      this.preloader = new Preloader({
        body: this.body,
        id: this.preloaderInView,
      })
      // hide hero assets to animate when preloader has been run
      this.header.set()
      this.preloader.on("completed", this.onPreloaded.bind(this))
    } else {
      this.header.set()

      console.log("is this casuing issue")

      if (this.HeroManager) {
        if (this.HeroManager.theHero) this.HeroManager.theHero.onLoad()
      }

      if (this.gdpr) this.cookiePolicy = new CookiePolicy()
      this.header.animate()
    }

    if (this.modalInView) this.modal = new Modal()

    if (this.cursorOnPage) {
      // Initialize custom cursor
      this.mouse = { x: 0, y: 0 }
      this.cursor = new Cursor({ mouse: this.mouse })
    }
  }

  createContent() {
    this.scrollWrapper = document.querySelector(".locomotive-scroll-container")
    // this.smoothScroll = new Scroll({ container: this.scrollWrapper })
    this.smoothScroll = new Scroll()
    this.HeaderFullWidthNavID = document.querySelector(
      ".js-header-full-width-nav"
    )
    this.body = document.querySelector("body")
    this.modalParent = document.querySelector(".js-modalParent")

    if (this.HeaderFullWidthNavID) {
      this.header = new HeaderFullWidthNav({
        id: this.HeaderFullWidthNavID,
        body: this.body,
        scroll: this.smoothScroll,
        container: this.scrollWrapper,
      })
    } else {
      this.header = new Header({
        scroll: this.smoothScroll,
        container: this.scrollWrapper,
      })
    }
  }

  onPreloaded() {
    // check if there is a hero on the page
    this.header.animate()
    //  console.log('yo preloadeed')

    // check if there is a hero on the page
    if (this.HeroManager) {
      if (this.HeroManager.theHero) this.HeroManager.theHero.onLoad()
    }

    //delete preloader
    this.preloader.destroy()

    // run hero animations
    if (this.gdpr) this.cookiePolicy = new CookiePolicy()
  }

  checkforGutenberg() {
    const hero = document.querySelector("[data-hero]")
    //  const block = document.querySelector('[data-block]')
    if (hero) {
      /*post-title-0 is ID of Post Title Textarea*/
      //Actual functions goes here
      this.blocksLoaded = true
      this.createHero()
      this.createBlocks()
    }
    if (this.blocksLoaded) {
      clearInterval(this.loadingTimer)
    }
  }

  runGutenberg() {
    this.consoleMessage()
    console.log("this is the backend")
    // check for heros  & run them
    this.blocksLoaded = false
    this.loadingTimer = setInterval(this.checkforGutenberg.bind(this), 500)
  }

  createHero() {
    this.currentHero = document.querySelector("[data-hero]")

    this.HeroManager = new HeroManager({
      hero: this.currentHero,
      header: this.header,
      scroll: this.smoothScroll,
      screenSize: this.screenSize,
      preloader: this.preloader,
      modalParent: this.modalParent,
    })
  }

  createBlocks() {
    this.blocks = [...document.querySelectorAll("[data-block]")]
    this.blocksOnPage = []
    // Check this if you want to create a new JS Block
    this.BlockManager = new BlockManager({
      header: this.header,
      blocks: this.blocks,
      scroll: this.smoothScroll,
      modalParent: this.modalParent,
      wrapper: this.scrollWrapper,
      screenSize: this.screenSize,
    })
  }

  detection() {
    this.screenSize = ""
    this.detection = new Detection(this.screenSize)
    this.screenSize = this.detection.screenSize
  }

  onResize() {
    // re calculate screen size
    this.detection.init()
    this.screenSize = this.detection.screenSize
    // when we have smooth scroll enabled
    this.smoothScroll
      ? ""
      : ScrollTrigger.addEventListener("refresh", () =>
          this.smoothScroll.scroll.update()
        )

    this.header.onResize(this.screenSize)

    if (this.heroManager) {
      if (this.HeroManager.theHero) {
        this.HeroManager.theHero.onResize()
      }
    }
    // run on Resize for all blocks
    if (this.BlockManager) {
      this.BlockManager.onResize(this.screenSize)
    }
  }

  addEventListeners() {
    // check for gutenberg
    if (typeof wp != "undefined") {
      if (wp.blockEditor) {
        // GUTENBERG
        window._wpLoadBlockEditor.then(this.runGutenberg.bind(this))
        return
      }
    }

    // check for frontend
    if (this.cursorOnPage) {
      // Mouse effects on all links and others
      ;[...document.querySelectorAll("a, button, .accordian-item")].forEach(
        (link) => {
          link.addEventListener("mouseenter", () => this.cursor.enter())
          link.addEventListener("mouseleave", () => this.cursor.leave())
        }
      )
    }

    window.addEventListener("resize", this.onResize.bind(this))

    if (window.lenis)
      window.lenis.on("scroll", this.header.scrolling.bind(this))

    if (this.devTools) {
      const gridSelector = this.devTools.querySelector("#griddevtools")
      // console.log(this.devTools)
      const grid = document.querySelector(".dt-grid")
      gridSelector.addEventListener("change", (event) => {
        if (event.currentTarget.checked) {
          grid.classList.add("on")
        } else {
          grid.classList.remove("on")
        }
      })
    }
  }
}

const website = new App()
