import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

import Component from "../Classes/Component"

export default class Header extends Component {
  constructor(scroll, container) {
    super({
      element: "header",
      elements: {
        headerType: ".header-type",
        toggleIcon: ".menu_toggle",
        line: ".homepage-line",
        logo: "#logo",
        headerButton: ".js-button",
        mobileMenu: ".mobile-sidebar",
        pageBackground: ".page-cover",
        page: "main#main",
        fullScreenNav: ".fullscreen-nav-js",
        revealActive: ".header__activate_scrolling",
        progressBar: ".header__progress--inner",
        menuItem: ".menu-item-has-children a:not(.sub-menu a)",
        menuItemHasChildren: ".menu-item-has-children",
        subMenus: ".sub-menu",
        lineHolder: ".line-holder",
      },
    })

    this.screenLarge = window.matchMedia("(min-width: 992px)")
    this.scrollUp = "js-scroll-up"
    this.scrollDown = "js-scroll-down"
    this.lastScroll = 0
    this.windowX = null
    this.winY = null
    this.scroll = scroll

    this.init()
  }

  set() {
    if (this.elements.headerButton)
      gsap.set(this.elements.headerButton, { autoAlpha: 0 })

    if (this.elements.logo) gsap.set(this.elements.logo, { autoAlpha: 0 })

    if (this.elements.toggleIcon)
      gsap.set(this.elements.toggleIcon, { autoAlpha: 0 })

    if (this.elements.line)
      gsap.set(this.elements.line, { yPercent: 110, transformOrigin: "bottom" })
  }

  animate() {
    this.tl = gsap.timeline({
      defaults: {
        ease: "expo",
        duration: 0.4,
      },
    })

    if (this.elements.line)
      this.tl.to(
        this.elements.line,
        { yPercent: 0, ease: "back.inOut(1.7)" },
        0
      )

    this.tl
      .to(this.elements.logo, { autoAlpha: 1 })
      .to(
        [this.elements.headerButton, this.elements.toggleIcon],
        { autoAlpha: 1, stagger: 0.03 },
        "-=0.2"
      )
  }

  closeDropdowns(barba) {
    const dropdwns = [...document.querySelectorAll(".sm-dropdown")]
    dropdwns.forEach((toggle) => toggle.classList.remove("js-open"))

    if (barba) {
      dropdwns.forEach((toggle) => toggle.classList.remove("open-menu"))
      this.elements.subMenus.forEach((menu) => {
        menu.classList.remove("active-menu")
        if (this.screenSize === "mobile" || this.screenSize === "tablet") {
          menu.style.height = `0px`
          menu.style.paddingTop = `0px`
        }
      })
    }
  }

  toggleSubMenu(event) {
    const icon = event.currentTarget
    event.preventDefault()

    const currentSubMenu = icon.parentNode.querySelector(".sub-menu")

    if (this.elements.headerType.classList.contains("logo_left")) {
      // Logo Left
    } else if (this.elements.headerType.classList.contains("logo_middle")) {
      // logo middle

      if (Array.isArray(this.elements.subMenus)) {
        // close all other subMenus when selecting a new submenu
        this.elements.subMenus.forEach((menu) => {
          menu.classList.remove("active-menu")
          // on mobile hide dropdowns for a smooth press
          if (this.screenSize === "mobile" || this.screenSize === "tablet") {
            menu.style.height = `0px`
          }
        })

        //this.closeDropdowns()

        // // on fullscreen with multiple dropdowns choose selected dropdowns
        // if (icon.classList.contains('open-menu')) {
        //   icon.classList.remove('open-menu')
        // } else {
        //   icon.classList.add('open-menu')
        // }
      }
    } else {
    }
    //show or hide each menu
    if (icon.classList.contains("js-open")) {
      currentSubMenu.classList.remove("active-menu")
      icon.classList.remove("js-open")

      if (this.screenSize === "mobile" || this.screenSize === "tablet") {
        currentSubMenu.style.height = `0px`
        currentSubMenu.style.paddingTop = `0px`
      }

      // on first click fullscreen nav menu with multiple submenus
    } else if (
      (icon.classList.contains("open-menu") &&
        !icon.classList.contains("js-open")) ||
      icon.classList.value === "sm-dropdown"
    ) {
      this.closeDropdowns()

      currentSubMenu.classList.add("active-menu")
      icon.classList.add("js-open")

      if (this.screenSize === "mobile" || this.screenSize === "tablet") {
        currentSubMenu.style.height = `${currentSubMenu.scrollHeight + 10}px`
        currentSubMenu.style.paddingTop = `10px`
      }
    } else if (
      !icon.classList.contains("js-open") &&
      this.elements.headerType.classList.contains("logo_middle") &&
      Array.isArray(this.elements.subMenus)
    ) {
      currentSubMenu.classList.remove("active-menu")
      icon.classList.remove("js-open")

      if (this.screenSize === "mobile" || this.screenSize === "tablet") {
        currentSubMenu.style.height = `0px`
        currentSubMenu.style.paddingTop = `0px`
      }
    } else {
      currentSubMenu.classList.add("active-menu")
      if (this.screenSize === "mobile" || this.screenSize === "tablet") {
        currentSubMenu.style.height = `${currentSubMenu.scrollHeight + 10}px`
        currentSubMenu.style.paddingTop = `10px`
      }
      icon.classList.add("js-open")
    }
  }

  init() {
    super.create()
    //  this.choosePreloader()
    //  console.log(this)
    document.querySelector("[data-hero]")
      ? this.headerChangeState("white")
      : this.headerRevertState()

    this.createDropdownIcon()
    this.eventListeners()
    console.log("called twice?")
    this.checkSectionforHeader()
  }

  createDropdownIcon() {
    if (this.elements.menuItem) {
      if (Array.isArray(this.elements.menuItem)) {
        // an array
        this.elements.menuItem.forEach((item) => {
          // create a plus span
          const plus = `<button class='sm-dropdown'></button>`
          item.insertAdjacentHTML("afterend", plus)
        })
      } else {
        // a single element
        // create a plus span
        const plus = `<button class='sm-dropdown'></button>`
        this.elements.menuItem.insertAdjacentHTML("afterend", plus)
      }
    }
  }

  onResize(screenSize) {
    // console.log('header-resize')
    this.screenSize = screenSize
    // console.log(this.screenSize)

    // on mobile hide dropdowns for a smooth press
    if (this.screenSize === "mobile" || this.screenSize === "tablet") {
      this.elements.subMenus.forEach((menu) => {
        menu.style.height = `0px`
        menu.style.paddingTop = `0px`
      })
    }
  }

  removeCover() {
    this.closeMenu()
    setTimeout(() => {
      this.enterHeaderIn()
    }, 1000)
  }

  eventListeners() {
    if (this.elements.toggleIcon) {
      this.elements.toggleIcon.addEventListener(
        "click",
        this.toggleMenu.bind(this)
      )
    }
    window.addEventListener("resize", this.removeCover.bind(this))

    if (this.elements.menuItem) {
      if (Array.isArray(this.elements.menuItem)) {
        // an array
        this.elements.menuItemHasChildren.forEach((element) => {
          const icon = element.querySelector(".sm-dropdown")
          if (icon) {
            icon.addEventListener("click", this.toggleSubMenu.bind(this))
          }
        })
      } else {
        // a single element
        this.elements.menuItemHasChildren
          .querySelector(".sm-dropdown")
          .addEventListener("click", this.toggleSubMenu.bind(this))
      }
    }
  }

  enterHeaderIn() {
    gsap.to(this.element, { yPercent: 0, ease: "expo", duration: 0.4 })
  }

  headerChangeState(value) {
    console.log(value)
    console.log("state being changed?")
    this.element.setAttribute("data-state", value)
  }

  headerRevertState() {
    console.log("why revert?")
    this.element.setAttribute("data-state", "white")
  }

  checkSectionforHeader() {
    console.log("called")
    // use intersection observer to see if header is overlapping data-header sections
    // if so run headerWhite function
    // https://www.smashingmagazine.com/2021/07/dynamic-header-intersection-observer/
    // Only works on desktop

    this.headerStateSections = [
      ...document.querySelectorAll("[data-header-state]"),
    ]

    // if No sections
    if (!this.headerStateSections.length) return

    // if Mega Menu is open revert header state

    if (this.elements.fullScreenNav.classList.contains("show-fullscreen-nav")) {
      if (this.headerRevertState) this.headerRevertState()
      return
    }

    let halfHeight = this.element.offsetHeight / 2

    const options = {
      // root is the scrollwrapper so can be used with scrollsmoother or just default
      rootMargin: `-${this.element.offsetHeight}px 0px -${
        window.innerHeight - this.element.offsetHeight
      }px 0px`,
      threshold: 0,
      // trackVisibility: true,
      // delay: 100
    }

    /* The callback that will fire on intersection */
    const onIntersect = (entries) => {
      // wipe each time
      this.headerResults = []
      this.headerNameState

      entries.forEach((entry) => {
        // return if section is not visible
        if (entry.isIntersecting) {
          this.headerNameState = entry.target.dataset.headerState
          this.headerResults.push(true)
        } else {
          this.headerResults.push(false)
        }
        return this.headerResults
      })

      // we have an array of all results of all divs are in view or not
      // if one of the results is true set the header, if all are false revert

      // checks whether an element is true
      const result = (element) => element === true

      if (this.headerResults.some(result)) {
        if (this.headerChangeState) this.headerChangeState(this.headerNameState)
      } else {
        if (this.headerRevertState) this.headerRevertState()
      }
    }

    /* Create the observer */
    const observer = new IntersectionObserver(onIntersect, options)
    const that = this

    /* Set our observer to observe each section */
    this.headerStateSections.forEach((section) => {
      observer.observe(section)
    })
  }

  // used for barba
  closeMenu() {
    // console.log('closed')
    this.elements.toggleIcon.classList.remove("toggle_on")
    this.elements.pageBackground.classList.remove("page-cover-opacity")
    this.elements.page.classList.remove("paged")
    this.elements.fullScreenNav.classList.remove("show-fullscreen-nav")
    const barba = "yes"
    this.closeDropdowns(barba)
    document.getElementsByTagName("body")[0].style.overflow = "visible"

    gsap.to(this.element, { yPercent: -110, ease: "expo", duration: 0.4 })
  }

  toggleMenu() {
    this.elements.toggleIcon.classList.toggle("toggle_on")

    if (!this.elements.toggleIcon.classList.contains("toggle_on")) {
      // MAKE HEADER WHITE WHEN SCROLL PAST HERO
      // needs to have data-scroll-id="hero" in div
      //   console.log(this.scroll.scroll.scroll.scroll.currentElements)

      if (this.scroll.scroll.scroll.scroll.currentElements.hero) {
        this.inview =
          this.scroll.scroll.scroll.scroll.currentElements.hero.inView
      } else {
        this.inview = null
      }

      if (this.inview === true && document.querySelector("[data-hero]")) {
        // make transaparent
        this.headerChangeState("transparent")
      } else {
        // make white
        this.headerRevertState()
      }
    }

    if (this.elements.line) {
      gsap.to(this.elements.line, {
        yPercent: -110,
        duration: 0.3,
        ease: "back.inOut(1.7)",
        transformOrigin: "top",
        onComplete: () => {
          this.elements.lineHolder.parentNode
            ? this.elements.lineHolder.parentNode.removeChild(
                this.elements.lineHolder
              )
            : ""
        },
      })
    }

    // IF we have logo - left header selected
    if (this.elements.mobileMenu) {
      this.elements.mobileMenu.classList.toggle("mobile-menu-appear")
      this.elements.pageBackground.classList.toggle("page-cover-opacity")
      this.elements.page.classList.toggle("paged")
    }

    // IF we have logo middle selected
    // This will work if scrolling header is remvoed otherwise please check below in scorlling function

    if (this.elements.fullScreenNav) {
      this.elements.pageBackground.classList.toggle("page-cover-opacity")
      this.elements.page.classList.toggle("paged")
    }

    if (this.elements.fullScreenNav.classList.contains("show-fullscreen-nav")) {
      this.elements.fullScreenNav.classList.remove("show-fullscreen-nav")
      // Remove listener to re-enable scroll
      document.getElementsByTagName("body")[0].style.overflow = "visible"
    } else {
      this.elements.fullScreenNav.classList.add("show-fullscreen-nav")
      // disable scroll when fullscreen nav is visible
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
      // mkae header white
      this.headerRevertState()
    }
  }

  //accessed through index.js
  scrolling(obj) {
    ScrollTrigger.update()

    if (this.header.elements.line) {
      gsap.to(this.header.elements.line, {
        yPercent: 110,
        duration: 0.3,
        ease: "back.inOut(1.7)",
        transformOrigin: "top",
      })
    }

    let size = obj.limit.y - window.innerHeight - obj.limit.x

    //update progress bar with width using gsap utility
    // map range from pixels to 100
    let widthToProgress = gsap.utils.mapRange(0, size, 0, 100)
    let howMuchScrolled = widthToProgress(obj.scroll.y)
    // console.log(howMuchScrolled)

    this.header.elements.progressBar.style.height = `${howMuchScrolled}%`

    // MAKE HEADER WHITE WHEN SCROLL PAST HERO
    // needs to have data-scroll-id="hero" in div

    // this.hero = this.smoothScroll.scroll.scroll.currentElements["hero"]
    // if (this.hero) {
    //   if (this.hero.inView === true && document.querySelector("[data-hero]")) {
    //     this.headerChangeState("transparent")
    //   }
    // } else {
    //   // make white
    //   this.headerRevertState()
    // }
    //------------------------------------------------------------------------------------------

    // WHEN YOU SCROLL DOWN HIDE HEADER, REVEAL WHEN SCROLLING UP

    super.create()
    // accessed through index.js hence why we need to use 'header.'

    if (this.header.elements.revealActive != null) {
      this.header.currentScroll = window.pageYOffset
      if (this.header.currentScroll === 0) {
        this.header.element.classList.remove(this.header.scrollUp)
        return
      }

      if (
        this.header.currentScroll > this.header.lastScroll &&
        !this.header.element.classList.contains(this.header.scrollDown)
      ) {
        // DOWN
        if (
          this.header.elements.fullScreenNav.classList.contains(
            "show-fullscreen-nav"
          )
        ) {
        } else {
          this.header.element.classList.remove(this.header.scrollUp)
          this.header.element.classList.add(this.header.scrollDown)
        }

        // this.header.elements.toggleIcon.classList.remove('toggle_on')
      } else if (
        this.header.currentScroll < this.header.lastScroll &&
        this.header.element.classList.contains(this.header.scrollDown)
      ) {
        // UP
        this.header.element.classList.remove(this.header.scrollDown)
        this.header.element.classList.add(this.header.scrollUp)
      }
      this.header.lastScroll = this.header.currentScroll
    }
  }
}
