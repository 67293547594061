import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export const Parallax = (section) => {
  gsap.set(section, { scale: 1.2 })

  return gsap
    .timeline({
      scrollTrigger: {
        trigger: section.parentElement,
        scrub: true,
        invalidateOnRefresh: true,
        start: "top bottom",
        end: "bottom bottom",
        // markers: true,
      },
    })
    .fromTo(
      section,
      {
        yPercent: -15,
      },
      {
        yPercent: 0,
        ease: "none",
      }
    )
}
