import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default class Process {
  constructor({ scroll, container, screenSize, id, header }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.screenSize = screenSize
    this.header = header
    this.currentIndex

    // DOM Elements
    this.DOM.stages = [...this.DOM.el.querySelectorAll(".js-stage")]
    this.DOM.stageImgs = [...this.DOM.el.querySelectorAll(".js-stage-img")]
    this.DOM.frame = this.DOM.el.querySelector(".js-frame")
    this.DOM.underlines = [...this.DOM.el.querySelectorAll(".js-underline")]
    this.DOM.circles = [...this.DOM.el.querySelectorAll(".js-circle")]
    this.DOM.tabs = this.DOM.el.querySelector(".js-tabs")
    this.DOM.filters = [...this.DOM.el.querySelectorAll(".js-tab-filter")]
    this.DOM.outerWrappers = gsap.utils.toArray(".js-outer")
    this.DOM.innerWrappers = gsap.utils.toArray(".js-inner")
    this.DOM.titles = gsap.utils.toArray(".js-stage-title")
    this.DOM.subtitles = gsap.utils.toArray(".js-stage-subtitle")
    this.DOM.buttons = [
      ...this.DOM.el.querySelectorAll(".js-staged-version-button"),
    ]
    this.DOM.descGroup = gsap.utils.toArray(".js-desc-group")
    this.DOM.descs = gsap.utils.toArray(".js-desc")
    this.DOM.overlay = this.DOM.el.querySelector(".js-overlay")
    this.DOM.stageInfos = [...this.DOM.el.querySelectorAll(".js-stage-info")]

    // timeline
    this.DOM.timeline = this.DOM.el.querySelector(".js-timeline")
    this.observer

    // Tools for animation
    this.matchMedia = gsap.matchMedia()

    this.init()
  }

  // Pins tabs and timeline to page
  pinFrame() {
    this.matchMedia.add(
      // Desktop
      "(min-width: 992px)",
      () => {
        ScrollTrigger.create({
          trigger: this.DOM.el,
          scroller: this.scroll.scrollContainer,
          pin: this.DOM.frame,
          pinType: "fixed",
          start: `top top`,
          end: `bottom bottom`,
          // onUpdate: () => {
          //   this.scroll.scroll.update()
          //   ScrollTrigger.refresh()
          // },
          pinReparent: true,
          anticipatePin: 1,
          // markers: true,
        })
      }
    )
  }

  // run on resize
  onResize() {}

  set() {
    // timeline set
    this.DOM.timeline.style.height = `${(this.DOM.stages.length * 100) / 3}%`
    // gsap.set(this.DOM.timeline, {yPercent: 100 / this.DOM.stages.length})

    // set each slide
    gsap.set([this.DOM.stageImgs, this.DOM.stageInfos], { autoAlpha: 0 })

    gsap.set(
      [this.DOM.titles, this.DOM.subtitles, this.DOM.descs, this.DOM.buttons],
      { autoAlpha: 0, yPercent: -20 }
    )

    // remove classes on mobile
    this.matchMedia.add("(max-width: 991px)", () => {
      this.DOM.underlines.forEach((underline) =>
        underline.classList.remove("js-active")
      )
      this.DOM.circles.forEach((circle) => circle.classList.remove("js-active"))
    })
  }

  animateStages() {
    this.DOM.stages.forEach((stage, index) => {
      // run scrolltrigger on each slide
      this.currentIndex = index

      this.matchMedia.add(
        // Desktop
        "(min-width: 992px)",
        () => {
          // When we enter and leave section
          ScrollTrigger.create({
            trigger: stage,
            start: "top center",
            end: "bottom center",
            //  markers: true,
            onEnter: () => {
              this.animateIn(index, stage)
            },
            onEnterBack: () => {
              this.animateIn(index, stage)
            },
            onLeave: () => {
              this.animateOut(index)
            },
            onLeaveBack: () => {
              this.animateOut(index)
            },
          })
        }
      )
    })
  }

  animateIn(index, stage) {
    const descs = [...this.DOM.descGroup[index].querySelectorAll(".js-desc")]

    const animateIn = gsap.timeline({
      defaults: {
        duration: 0.25,
        stagger: 0.05,
        ease: "power1.inOut",
      },
      onStart: () => {
        this.DOM.underlines[index].classList.add("js-active")
        this.DOM.circles[index].classList.add("js-active")
      },
    })

    const unit = 100 / this.DOM.stages.length
    let theIndex = index + 1
    // let theIndex
    //  index === 0 ? theIndex = 0 : theIndex = index + 1

    animateIn
      .to(
        this.DOM.timeline,
        {
          yPercent: unit - unit * theIndex,
          ease: "none",
          scrollTrigger: {
            scrub: true,
            trigger: stage,
            start: "top center",
            end: "bottom center",
            //  markers: true
          },
        },
        0
      )
      .to(
        [this.DOM.stageImgs[index], this.DOM.stageInfos[index]],
        {
          autoAlpha: 1,
          ease: "none",
        },
        0
      )
      .to(
        [
          this.DOM.titles[index],
          this.DOM.subtitles[index],
          this.DOM.buttons[index],
          descs,
        ],
        {
          autoAlpha: 1,
          yPercent: 0,
        }
      )

    // Make timeline scrub on scroll
  }

  destroy() {
    // destroy all scrolltriggers
    ScrollTrigger.getAll().forEach((t) => t.kill())
  }

  animateOut(index) {
    const descs = [...this.DOM.descGroup[index].querySelectorAll(".js-desc")]

    const animateIn = gsap.timeline({
      defaults: {
        duration: 0.25,
        stagger: 0.05,
        ease: "power1.inOut",
      },
      onStart: () => {
        this.DOM.underlines[index].classList.remove("js-active")
        this.DOM.circles[index].classList.remove("js-active")
      },
    })

    animateIn
      .to(
        [this.DOM.stageImgs[index], this.DOM.stageInfos[index]],
        {
          autoAlpha: 0,
          ease: "none",
        },
        0
      )
      .to(
        [
          this.DOM.titles[index],
          this.DOM.subtitles[index],
          this.DOM.buttons[index],
          descs,
        ],
        {
          autoAlpha: 0,
          yPercent: -20,
        }
      )
  }

  // Function that handles the click on a filter
  handleClick(e) {
    // prevent link form taking us away
    e.preventDefault()

    // get index to animate to
    const targetLink = e.currentTarget
    const index = this.DOM.filters.indexOf(targetLink)

    // hide everything
    this.DOM.overlay.classList.add("js-active")
    gsap.to(this.DOM.stageInfos, { autoAlpha: 0, duration: 0.1 })

    // remove current stuff
    this.DOM.underlines[this.currentIndex].classList.remove("js-active")
    this.DOM.circles[this.currentIndex].classList.remove("js-active")

    gsap.to(this.DOM.stageImgs[this.currentIndex], {
      autoAlpha: 0,
      ease: "none",
    })

    gsap.to(
      [this.DOM.titles, this.DOM.subtitles, this.DOM.descs, this.DOM.buttons],
      {
        autoAlpha: 0,
        yPercent: -20,
      }
    )

    // scroll to section

    console.log(this.DOM.stages[index])

    this.scroll.scroll.scrollTo(this.DOM.stages[index], {
      callback: () => {
        this.DOM.overlay.classList.remove("js-active")
        this.animateIn(index)
      },
    })
  }

  addEventlisteners() {
    this.clickEvent = this.handleClick.bind(this)

    this.DOM.filters.forEach((filter) => {
      filter.addEventListener("click", this.clickEvent)
    })
  }

  init() {
    this.set()
    this.pinFrame()
    this.animateStages()
    this.addEventlisteners()
  }
}
