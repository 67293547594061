import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)
import _ from "lodash"

// we are using the WP REST API to grab the posts for our WP FILTER
export default class PostDisplay {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.holder = this.DOM.el.querySelector(".blog__holder")
    this.DOM.buttons = [...this.DOM.el.querySelectorAll(".cat-list_item")]
    this.DOM.loading = this.DOM.el.querySelector(".bg__overlay")

    this.categories = []
    this.allPosts = []
    this.init()
  }

  async fetchPosts() {
    // grabs all posts
    const response = await fetch("/wp-json/wp/v2/posts?_embed")
    const data = await response.json()
    //   console.log(data)

    // get acf data for each post
    data.forEach((item) => {
      const cat = []
      item.categories.forEach((category) => {
        const catname = category.category_name.toLowerCase()
        cat.push(catname)
      })

      const obj = {
        image: item.featured_image_url,
        title: item.acf.article_title,
        author: item.acf.author,
        date: item.acf.article_date,
        readTime: item.acf.article_read_time,
        quote: item.acf.quote,
        permalink: item.link,
        categories: cat,
      }
      this.allPosts.push(obj)
    })
  }

  filter(event) {
    event.target.classList.add("disable")
    this.DOM.loading.classList.add("active")
    this.DOM.holder.innerHTML = ""

    this.filter = []
    // remove active for all buttons
    this.DOM.buttons.forEach((button) => {
      button.classList.remove("active")
    })
    // add active for selected filter
    event.target.classList.add("active")
    // grab data attribute
    this.currentCategory = event.target.getAttribute("data-slug")
    // match currentcategory with posts and create array of posts
    this.allPosts.forEach((post) => {
      if (_.includes(post.categories, this.currentCategory)) {
        this.filter.push(post)
      }
    })
    //    console.log(this.filter)

    // add loading then hide it after 2 secs
    setTimeout(() => {
      this.DOM.loading.classList.remove("active")
      event.target.classList.remove("disable")
    }, 3000)

    if (!this.filter.length) {
      // empty or does not exisit
      this.allPosts.forEach((post) => {
        this.showPosts(post)
      })
    } else {
      this.filter.forEach((post) => {
        this.showPosts(post)
      })
    }
  }

  showPosts(post) {
    const postItem = post

    setTimeout(() => {
      const postHTML = document.createElement("a")
      postHTML.classList.add("blog-item-post")
      postHTML.href = post.permalink
      postHTML.innerHTML = this.newPost(postItem)

      this.DOM.holder.appendChild(postHTML)
    }, 2000)
  }

  onResize() {
    this.DOM.loading.style.height = `${
      this.DOM.holder.getBoundingClientRect().height
    }px`
  }
  newPost(post) {
    return `
            <div class="blog-item">
                <article class="blog-item__inner">
                    <div class="blog__image">
                      ${post.image ? '<img src="' + post.image + '">' : ""}
                    </div>
                    <div class="blog-text">
                        ${
                          '<div class="pd-cat-button">' +
                          post.categories +
                          "</div>"
                        }
                        <h4>${post.title}</h4>
                          ${
                            post.author
                              ? "<strong><p>" + post.author + "</strong></p>"
                              : ""
                          }

                        <p class='blog-readtime'>
                        ${post.date} ${
      post.readTime ? "| " + post.readTime + "min read" : ""
    } 
                        </p>

                        ${
                          post.quote
                            ? '<div class="byline"><p>' +
                              post.quote +
                              "</p></div>"
                            : ""
                        }   
                        <button class="block-button">Full Article</button>
                    </div>
                </article>
            </div>    
        `
  }
  addEventListeners() {
    this.DOM.buttons.forEach((item) => {
      item.addEventListener("click", this.filter.bind(this))
    })
  }
  init() {
    this.onResize()
    this.fetchPosts()
    this.addEventListeners()
  }
}
