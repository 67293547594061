import { gsap, DrawSVGPlugin } from "gsap"
import EventEmitter from "../Classes/EventEmitter"
gsap.registerPlugin(DrawSVGPlugin)

export default class Preloader extends EventEmitter {
  constructor({ body, id }) {
    super()
    this.body = body
    this.isDestroyed = false
    this.DOM = { el: id }
    this.DOM.inner = this.DOM.el.querySelector(".js-preloader-inner")
    this.DOM.images = [...this.DOM.el.querySelectorAll("img")]
    this.DOM.titles = [...this.DOM.el.querySelectorAll(".js-title")]
    this.DOM.lastText = this.DOM.el.querySelector(".js-title-new")
    this.DOM.architecture = this.DOM.el.querySelector(".js-title-architecture")
    this.DOM.space = this.DOM.el.querySelector(".js-title-space")
    this.DOM.logo = this.DOM.el.querySelector(".js-preloader-two svg path")
    this.DOM.preloaderOne = this.DOM.el.querySelector(".js-preloader-one")
    this.DOM.preloaderTwo = this.DOM.el.querySelector(".js-preloader-two")
    this.init()
  }

  init() {
    this.DOM.inner.classList.remove("js-hide")
    gsap.set(this.DOM.logo, { drawSVG: "0%" })
    gsap.set([this.DOM.titles, this.DOM.lastText], {
      autoAlpha: 0,
      yPercent: -110,
    })
    gsap.set(this.DOM.architecture, { yPercent: 110 })
    gsap.set([this.DOM.space], { autoAlpha: 0 })
    gsap.set([this.DOM.preloaderOne, this.DOM.preloaderTwo], { autoAlpha: 1 })

    this.choosePreloader()
    this.animate()
  }

  choosePreloader() {
    this.tlPreloader = gsap.timeline({
      defaults: {
        ease: "power4.in",
        duration: 0.8,
        onStart: () => {
          //  show preloader two on other pages + home page after preloader one has run
          if (
            sessionStorage.showLoaderOne ||
            !this.body.classList.contains("home")
          ) {
            // Show Preloader Two
            gsap.set(this.DOM.preloaderOne, { autoAlpha: 0 })
          } else {
            // show Preloader One
            gsap.set(this.DOM.preloaderTwo, { autoAlpha: 0 })
          }
        },
      },
      onComplete: () => {
        sessionStorage.setItem("showLoaderOne", true)
        this.emit("completed", "yes")
      },
    })
  }

  animate() {
    if (sessionStorage.showLoaderOne) {
      // second pre-loader
      this.tlPreloader
        .to(this.DOM.logo, {
          drawSVG: "100%",
          ease: "none",
          duration: 0.85,
        })
        .to(
          this.DOM.el,
          { autoAlpha: 0, duration: 0.6, delay: 0.25, ease: "expo.out" },
          0
        )
    } else {
      // first pre-loader

      // if on home page show preloader one
      if (this.body.classList.contains("home")) {
        this.tlPreloader
          .to(this.DOM.titles, {
            autoAlpha: 1,
            yPercent: 0,
            stagger: 1,
            duration: 0.6,
            ease: "back.inOut(1.7)",
          })
          .to(
            this.DOM.titles,
            {
              delay: 0.8,
              autoAlpha: 0,
              yPercent: 50,
              stagger: 1,
              duration: 0.4,
              ease: "power4.in",
            },
            0
          )
          .to(this.DOM.space, { autoAlpha: 1, duration: 0.6 }, 0)
          .to(this.DOM.lastText, {
            autoAlpha: 1,
            yPercent: 0,
            ease: "back.inOut(1.7)",
            duration: 0.6,
          })
          .to(this.DOM.architecture, { yPercent: 0, ease: "back.inOut(1.7)" })
          .to(this.DOM.el, {
            delay: 0.2,
            autoAlpha: 0,
            duration: 1,
            ease: "expo.out",
          })
      } else {
        // if not on home page show preloader two
        // second pre-loader
        this.tlPreloader
          .to(this.DOM.logo, {
            drawSVG: "100%",
            ease: "none",
            duration: 0.85,
          })
          .to(
            this.DOM.el,
            { autoAlpha: 0, duration: 0.6, delay: 0.25, ease: "expo.out" },
            0
          )
      }
    }
  }

  destroy() {
    setTimeout(() => {
      this.DOM.el.parentNode.removeChild(this.DOM.el)
    }, 2500)
    this.isDestroyed = true
  }
}
