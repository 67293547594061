import { createWidget } from '@typeform/embed'

export default class Typeform{
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.formID = this.DOM.el.getAttribute("data-form")
        this.init()
    }

    init() {
        createWidget(this.DOM.formID, { 
            container: this.DOM.el, // you need an element with 'form' id
            hideHeaders: true,
            hideFooter: true,
          })
    }

}
