import { gsap } from "gsap"

import { ScrollTrigger } from "gsap/ScrollTrigger"

/* The following plugin is a Club GSAP perk */
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)

// Portfolio
// 1. check arrays loading in new content
// 3. add loading animation to load in new cotnent
// 4. lazy load in new images
//5. add links to new filter
// 5. chec naviagtion to new page
// 6. gcreate permalink

// we are using the WP REST API to grab the posts for our WP FILTER
export default class Portfolio {
  constructor({ scroll, container, screenSize, id, header }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.header = header
    this.container = container
    this.screenSize = screenSize
    this.DOM.holder = this.DOM.el.querySelector(".js-grid")
    this.DOM.links = [...this.DOM.el.querySelectorAll(".js-filter-item")]
    this.DOM.gridItems = [...this.DOM.el.querySelectorAll(".js-grid-item")]
    this.DOM.overlay = this.DOM.el.querySelector(".js-overlay")
    this.DOM.aside = this.DOM.el.querySelector(".js-aside")
    this.DOM.div = this.DOM.el.querySelector(".js-grid-holder")
    this.DOM.SVG = this.DOM.el.querySelector(".js-svg")
    this.DOM.SVGPath = this.DOM.SVG.querySelector("svg path")
    this.firstPageLoad = true
    this.cachedWidth = window.innerWidth
    this.matchMedia = gsap.matchMedia()

    this.columnWrappers = {}
    this.categories = []
    this.allProjects = []
    this.set()
    this.init()
    this.SVGLoadingAnimation()
  }

  set() {
    // gsap.set(this.DOM.holder, {autoAlpha:0, yPercent: 25})
    //  gsap.set(this.DOM.aside, {autoAlpha:0})
    gsap.set(this.DOM.SVGPath, { drawSVG: "0%" })
  }

  createHolderHeight() {
    // get height of section and create an element the same size to be removed when we get results
    this.currentHeight = this.DOM.holder.getBoundingClientRect().height
    this.DOM.div.style.height = `${this.currentHeight}px`
  }

  SVGLoadingAnimation() {
    // show logo svg
    this.DOM.SVG.classList.remove("js-hide")

    // repeat logo line animation
    gsap.to(this.DOM.SVGPath, {
      drawSVG: "100%",
      duration: 1.8,
      ease: "power1.out",
      repeat: -1,
      yoyo: true,
    })

    // Let animation run for two seconds then hide overlay
    setTimeout(() => {
      gsap.to(this.DOM.overlay, {
        autoAlpha: 0,
        ease: "expo.out",
        duration: 0.5,
      })
      // remove repeat animation
      gsap.killTweensOf(this.DOM.SVGPath)
    }, 1500)
  }

  SVGLoadingAnimationIn() {
    this.loadingAnimationIn = gsap.timeline({
      defaults: {
        ease: "expo.out",
      },
      onStart: () => {
        this.createHolderHeight()
        gsap.set(this.DOM.SVGPath, { drawSVG: "0%" })
      },
    })

    this.loadingAnimationIn
      .to(this.DOM.overlay, {
        autoAlpha: 1,
        ease: "expo.out",
        duration: 0.01,
      })
      .to(this.DOM.SVGPath, {
        drawSVG: "100%",
        duration: 1.8,
        ease: "power1.out",
        repeat: -1,
        yoyo: true,
      })
  }

  SVGLoadingAnimationOut() {
    this.loadingAnimationOut = gsap.timeline({
      defaults: {
        ease: "expo.out",
      },
      delay: 1.5,
      onComplete: () => {
        gsap.killTweensOf(this.DOM.SVGPath)
        gsap.set(this.DOM.SVGPath, { drawSVG: "0%" })
        gsap.set(this.DOM.SVG, { autoAlpha: 1 })
      },
    })

    this.loadingAnimationOut
      .to(this.DOM.SVG, { autoAlpha: 0, duration: 0.25 }, 0)
      .to(
        this.DOM.overlay,
        {
          autoAlpha: 0,
          duration: 0.75,
        },
        0
      )
  }

  async fetchPosts() {
    // grabs all posts
    const response = await fetch("/wp-json/my_endpoint/v1/our-projects")
    const data = await response.json()

    // get acf data for each post
    data.forEach((item) => {
      const categories = []

      if (item.categories) {
        item.categories.forEach((category) => {
          const catname = category.name
          categories.push(catname)
        })
      }

      const obj = {
        image: item.image,
        title: item.acf.hero.title,
        permalink: item.link,
        categories: categories,
      }
      this.allProjects.push(obj)
    })

    // IF LINK POSSESSES FILTER HASH
    if (window.location.hash && window.innerWidth > 768) {
      let typeLink, type
      // 1. check hash matches own of our search filters
      for (let i = 0; i < this.DOM.links.length; i++) {
        type = this.DOM.links[i].dataset.type.toLowerCase().replace(/ /g, "-")
        if (type === window.location.hash.substring(1)) {
          typeLink = true
          break
        }
        typeLink = false
      }
      if (typeLink) {
        this.filterByHash(type)
      }
    }

    // posts fetched allow next time on resize
    this.firstPageLoad = false
  }

  filter(e) {
    this.DOM.holder.classList.add("js-hide")
    this.SVGLoadingAnimationIn()

    // scroll to top
    if (window.lenis) window.lenis.scrollTo(0, 0)

    this.DOM.holder.innerHTML = ""

    // GET FILTERED POSTS
    this.filteredPosts = []

    // remove active for all buttons
    this.DOM.links.forEach((link) => {
      link.classList.remove("js-active")
    })

    e.target.classList.add("js-active")
    // e.target.classList.add('disable')

    // grab data attribute
    this.currentCategory = e.target.getAttribute("data-type")

    //update permalink
    const hash = this.currentCategory.toLowerCase().replace(/ /g, "-")
    window.location.hash = hash

    // match currentcategory with posts and create array of posts
    if (this.currentCategory === "All Projects") {
      this.filteredPosts = this.allProjects
    } else {
      this.filteredPosts = this.findChoosenPost(
        this.allProjects,
        this.currentCategory
      )
    }

    // if empty or doesn not exisits
    if (this.filteredPosts.length) {
      this.filteredPosts.forEach((post) => {
        this.showPosts(post)
      })
    }

    // afterwards restore DOM
    this.DOM.holder.classList.remove("js-hide")
    this.SVGLoadingAnimationOut()
  }

  // find work that matches the currnet search term with the work types
  findChoosenPost(array, term) {
    const choosenPosts = []
    array.forEach((post) => {
      post.categories.forEach((type) => {
        // make search terms lowercase and have dashes for spaces
        const search = term.toLowerCase().replace(/ /g, "-")
        const name = type.toLowerCase().replace(/ /g, "-")

        if (name === search) choosenPosts.push(post)
      })
    })
    return choosenPosts
  }

  showPosts(post) {
    const postItem = post
    const postHTML = document.createElement("a")
    postHTML.classList.add("new-Portfolio__grid-item-link")
    postHTML.classList.add("js-grid-item")
    postHTML.href = post.permalink
    postHTML.innerHTML = this.newPost(postItem)

    this.DOM.holder.appendChild(postHTML)
    this.DOM.div.style.height = "auto"

    ScrollTrigger.update()
    // this.scroll.update()
  }

  filterByHash(term) {
    this.DOM.holder.innerHTML = ""

    // GET FILTERED POSTS
    this.filteredPosts = []

    // 3. js-display filter link
    this.DOM.links.forEach((link) => {
      const type = link.dataset.type.toLowerCase().replace(/ /g, "-")
      link.classList.remove("js-active")
      if (term === type) {
        link.classList.add("js-active")
      }
    })

    // update currentcategory
    this.currentCategory = term

    // 4. Filter posts based on filter term
    this.filteredPosts = this.findChoosenPost(
      this.allProjects,
      this.currentCategory
    )

    // if empty or doesn not exisits
    if (this.filteredPosts.length) {
      this.filteredPosts.forEach((post) => {
        this.showPosts(post)
      })
    }
  }

  addAllProjectsOnMobile() {
    if (!this.firstPageLoad) {
      let checkMobile = gsap.matchMedia()

      let newWidth = window.innerWidth
      // on Mobile run all projects
      checkMobile.add("(max-width: 768px)", () => {
        // only run this on paageSize not page load
        // sstop rerunning on mobile with height changing due to scroll bar

        if (newWidth !== this.cachedWidth) {
          this.DOM.holder.classList.add("js-hide")

          this.SVGLoadingAnimationIn()
          this.DOM.holder.innerHTML = ""

          // GET FILTERED POSTS
          this.filteredPosts = []

          this.filteredPosts = this.findChoosenPost(
            this.allProjects,
            "All Projects"
          )

          // if empty or doesn not exisits
          if (this.filteredPosts.length) {
            this.filteredPosts.forEach((post) => {
              this.showPosts(post)
            })
          }

          // afterwards restore DOM
          this.DOM.holder.classList.remove("js-hide")
          this.SVGLoadingAnimationOut()

          // this.scroll.update()
          ScrollTrigger.update()
        }
      })

      checkMobile.add("(min-width: 769px)", () => {
        // console.log('ycccyl')
      })
    }
  }

  onResize() {
    this.createHolderHeight()

    // this.DOM.overlay.style.height = `${this.DOM.holder.getBoundingClientRect().height}px`
    this.addAllProjectsOnMobile()

    // refresh scrollTriggers
    ScrollTrigger.refresh()
  }
  newPost(post) {
    return `
            <article class="new-Portfolio__grid-item">
                    <div class="new-Portfolio__grid-item-image">
                      ${
                        post.image
                          ? '<img class="wp-post-image" src="' +
                            post.image +
                            '">'
                          : ""
                      }
                    </div>
                    <div class="new-Portfolio__grid-item-text-overlay">
                        <div class="Portfolio__grid-item-categories">
                        ${post.categories
                          .map((key) => {
                            return (
                              '<div class="new-Portfolio__grid-item-category">' +
                              key +
                              "</div>"
                            )
                          })
                          .join("")}
                        </div>
                        <h4 class='new-Portfolio__grid-item-title'>${
                          post.title
                        }</h4>
                        <button class="new-Portfolio__grid-item-button">See Project</button>
                    </div>
            </article>
       
        `
  }
  addEventListeners() {
    this.DOM.links.forEach((item) => {
      item.addEventListener("click", this.filter.bind(this))
    })
  }
  pinSidebar() {
    this.matchMedia.add("(min-width: 992px)", () => {
      ScrollTrigger.create({
        trigger: this.DOM.aside,
        endTrigger: this.DOM.holder,
        start: `top top+=${this.header.DOM.el.offsetHeight - 2}`, // when the top of the trigger hits the top of the viewport
        //   end: `bottom-=${this.header.DOM.el.offsetHeight - 2} bottom`, // end after scrolling 500px beyond the start
        end: `${
          this.DOM.holder.offsetHeight - this.DOM.aside.offsetHeight
        } bottom+=${this.header.DOM.el.offsetHeight - 2}`,
        // scrub: true,
        // scroller: this.DOM.el,
        pinType: "transform",
        pinSpacing: false,
        pin: true,
        anticipatePin: 1,
        //markers: true,
      })
    })
  }

  init() {
    this.onResize()
    this.fetchPosts()
    this.addEventListeners()
    this.pinSidebar()
  }
}
